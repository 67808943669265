import SurveyStrings from "../../../utils/constants/Strings";
import {SURVEYS_URL} from "../../../utils/constants/environment";
import {EndPoints} from '../../../utils/constants/EndPoints';
import AppRoute from "../../../utils/constants/AppRoute";
import SessionService from "../../../services/Session-Service";
import SessionStorage from "../../../utils/constants/SessionStorage";

type HomeboxProps = {
    text: string;
    descriptionTitle?: string;
    descriptionBody?: string;
    link?: boolean
}
function HomeBox(props: HomeboxProps) {
    const { text, descriptionTitle, descriptionBody, link = true } = props
    //TODO: substitute hardcoded with dynamic links
    const links = [
        {
            label: SurveyStrings.HOMEBOX_WALKTHROUGH_LINK,
            path: `${SURVEYS_URL}${EndPoints.WALKTHROUGH}`
        },
        {
            label: SurveyStrings.HOMEBOX_FAQ_LINK,
            path: "/faq"
        },
        {
            label: SurveyStrings.SUPPORT,
            path: `${AppRoute.SURVEY}${AppRoute.SUPPORT}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID) ?? '_'}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID) ?? '_'}`
        }
    ];

    return <>

        <div className='d-flex justify-content-center w-100 my-5'>

            <div className='customHeaders m-2 width_80 p-3'>

                <h4 className='my-3'> {text} </h4>

                {descriptionBody && descriptionTitle &&
                    <>
                        <h5 className='fw-bold'>{descriptionTitle}</h5>
                        <h5>{descriptionBody}</h5>
                    </>
                }

                {
                    link && links.map(link =>
                        <p key={`${link.path}-homebox-link`}>
                            {link.label === SurveyStrings.HOMEBOX_FAQ_LINK ? <a
                                rel='noreferrer'
                                href={link.path}
                            >
                                {link.label}
                            </a> : <a
                                target='_blank'
                                rel='noreferrer'
                                href={link.path}
                            >
                                {link.label}
                            </a>}
                        </p>
                    )

                }

            </div>

        </div>

    </>
}

export default HomeBox