import { EndPoints } from "../utils/constants/EndPoints"
import { BASE_URL } from "../utils/constants/environment"

type UploadResponse = {
    message: string,
    payload: string,
    status: number
}

class UploadService {

    async uploadFile(file: File): Promise<UploadResponse> {
        const form = new FormData();
        form.append('file', file);

        const options: RequestInit = {
            method: 'POST',
            body: form
        }

        return await fetch(`${BASE_URL}${EndPoints.UPLOAD}`, options)
            .then(res => res.json())
    }
}

export default new UploadService();