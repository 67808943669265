enum SurveyStrings {

    SECTION_SUBMIT_TITLE = "Vi invitiamo a verificare le informazioni inserite in ogni sezione, prima di procedere all'INVIO DATI. Successivamente all'INVIO DATI non sarà più possibile effettuare la modifica dei dati inseriti.",
    SECTION_SUBMIT_PROGRESS = "Stato di avanzamento: di seguito viene riportato lo stato di compilazione per ogni sezione.",
    HOMEBOX_HEADING = "Piano Triennale per l'Informatica nella Pubblica Amministrazione: Rilevazione Spesa ICT nella PA (edizione 2022)",
    LOGIN_FORM_ERROR = "Utilizzare il link univoco per accedere al questionario",
    HOMEBOX_TITLE = 'BENVENUTO',
    HOMEBOX_DESCRIPTION = "Il questionario della rilevazione sulla spesa ICT della PA ha l'obiettivo di integrare la raccolta di dati e informazioni sulle attività delle PA in accordo all’articolo n.14 bis del CAD per l'elaborazione del Piano Triennale",
    HOMEBOX_TEMPLATE_LINK = "Scarica il template",
    HOMEBOX_WALKTHROUGH_LINK = "Guida alla Compilazione",
    HOMEBOX_FAQ_LINK = "Consulta le FAQ",
    RECAP_TEXT = "Grazie per aver completato il questionario! Vi invitiamo a salvare in formato Excel o PDF i dati inseriti",
    APP_HEADER = "Si ricorda che per una migliore esperienza utente e per assicurare una corretta gestione delle informazioni è caldamente consigliato l'utilizzo di un browser javascript compliant (ad esempio Google Chrome, Firefox, Microsoft Edge)",
    UPLOADED_FILE = 'File Caricato : ',
    UPLOADED_NO_FILE = 'Nessun file caricato',
    START_DATE = "Data d'inizio",
    END_DATE = "Data fine",
    EXPORT_DATA = 'EXPORT DATI EXCEL',
    EXPORT_PDF= 'EXPORT DATI PDF',
    SEND_DATA = 'INVIO DATI',
    FORWARD = 'SALVA E PROCEDI',
    BACK = 'INDIETRO',
    SECTION_FORWARD = 'SEZIONE SUCCESSIVA',
    SECTION_BACK = 'SEZIONE PRECEDENTE',
    ERROR_FOOTER = 'Non è possibile procedere al salvataggio dei dati inseriti se prima non si risponde alle domande che presentano un errore',
    ERROR_SUBMIT = "Si ricorda che non è possibile procedere all'invio dati se non sono state compilate tutte le domande obbligatorie della sezione B e, nel caso di Regioni e Province Autonome, anche della sezione B Sanità",
    SUPPORT = 'Contatta il supporto',
    SUPPORT_GO = 'Vai alla pagina di supporto',
    SAVE_SUCCESS = "Il salvataggio è avvenuto correttamente!",
    SAVE_ERROR = "Il salvataggio delle risposte non è andato a buon fine, ti preghiamo di riprovare.",
    SAVE_ERROR_LINE_TWO = "Se l'errore dovesse ripetersi ti invitiamo a contattare il supporto al link sottostante.",
    TOAST_DISMISS = "Clicca in qualsiasi punto di questa notifica per cancellarla",
    INIT_GET_SUCCESS = "Login avvenuto con successo",
    INIT_GET_ERROR = "Errore nel recupero dei dati",
    INIT_GET_ERROR_LINE_TWO = "Riprova ad effettuare l'accesso con il link univoco o contatta il supporto per ricevere assistenza",
    GET_USER_ERROR = 'Errore nel recupero informazioni utente',
    GET_USER_ERROR_LINE_TWO = 'Il link univoco potrebbe essere corrotto, riprova o contatta il supporto per ricevere assistenza',
    SUPPORT_ERROR = "Errore nell'invio del messaggio",
    SUPPORT_ERROR_LINE_TWO = "I server potrebbero essere offline, riprova più tardi o contatta direttamente il supporto via mail",
    SUBMIT_ERROR = "Erore nell'invio del questionario",
    FINAL_CHECK_ERROR = "Errore nel controllo delle domande obbligatorie",
    DROPDOWN_ERROR = "Errore nel dropdown di navigazione",
    TEMPLATE_ERROR = "Errore nel download del template",
    ATTACHMENTS = 'Allegati : ',
    ATTACHMENTS_UPLOAD = 'Carica allegato',
    ATTACHMENTS_CANCEL = 'Cancella allegato',
    SEND = 'Invia richiesta',
    SENDING = 'Invio in corso...',
    BACK_HOME = 'Torna alla home',
    REOPEN = 'Richiesta riapertura del questionario',
    REOPEN_TEXT = "Compila questo modulo con le motivazioni per la richiesta di riapertura del questionario. Se lo ritieni utile, puoi allegare anche un file o un'immagine.",
    SUPPORT_TEXT = "Compila questo modulo per inviare segnalazioni e richiedere assistenza per il questionario. Se lo ritieni utile, puoi allegare anche un file o un'immagine per illustrare al meglio la problematica riscontrata.",
    SUPPORT_BODY = 'Inserisci qui il tuo messaggio per il supporto (minimo 30 caratteri)',
    SUPPORT_MAIL = 'Indirizzo e-mail',
    SUPPORT_SENT = 'La richiesta è stata inviata correttamente!',
    DOWNLOAD_WIN = 'I dati sono stati esportati correttamente',
    DOWNLOAD_FAIL = "Errore nell'esportazione dei dati",
    DOWNLOAD_FAIL_TWO = "Si prega di riprovare, se il problema persiste contattare l'assistenza al link sottostante ",
    LOADING = 'Caricamento in corso ...',
    DATA = 'I tuoi dati',
    MAIL = 'Mail',
    NAME = 'Nome',
    SURNAME = 'Cognome',
    ROLE = 'Ente',
    DOWNLOADING = 'Esportazione dati in corso',
    MATRIX_BANNER_ONE = 'Rilevazione 2019',
    MATRIX_BANNER_TWO =  'Rilevazione 2020',
    DOWNLOAD_WARNING = "Si prega di non lasciare o ricaricare la pagina fino al completamento dell'operazione",
    DOWNLOAD_MESSAGE = "Procedura di esportazione in corso",
    NEXT_PROJECT = "PROGETTO SUCCESSIVO",
    PREVIOUS_PROJECT = "PROGETTO PRECEDENTE",
    MANDATORY = 'Obbligatoria',
    ALERT_MAIL = 'Il file allegato non deve essere maggiore di 10 MB',
    UPLOAD_ERROR = 'Errore nel caricamento del file'
}
export default SurveyStrings;