class SessionService {

    setItem(key: string, value: string): void {
        return sessionStorage.setItem(key, value);
    }

    setObj(key: string, value: object): void {
        return sessionStorage.setItem(key, JSON.stringify(value));
    }

    getItem(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    exists(key: string): boolean {
        return !!sessionStorage.getItem(key);
    }

    clear() {
        sessionStorage.clear();
    }
    
}

export default new SessionService()