import genericAllSections from '../assets/mock-data/genericAllSections.json';
import OperationsModel from '../models/Operations';
import SectionConfiguration, { SectionNavigation } from '../models/Section-Configuration';
import { EndPoints } from '../utils/constants/EndPoints';
import { BASE_URL } from '../utils/constants/environment';
import SessionStorage from '../utils/constants/SessionStorage';
import SessionService from './Session-Service';

type ResponseSectionConfiguration = {
    message: string,
    payload: {
        config: {
            sections: SectionConfiguration[]
        },
        operations: OperationsModel,
        dropdown: SectionNavigation[]
    },
    status: number
}

type ResponseDropdown = {
    message: string,
    payload: {
        dropdown: SectionNavigation[]
    },
    status: number
}

class NavigateService {

    public getRecap(): Promise<SectionConfiguration[]> {
        return new Promise((resolve) => {
            resolve(genericAllSections)
        });
    }

    async getSection(): Promise<ResponseSectionConfiguration> {
        const params = {
            userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID),
            surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID)
        };

        const requestOptions: RequestInit = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }

        return await fetch(`${BASE_URL}${EndPoints.SURVEY}/${params.userId}/${params.surveyId}`, requestOptions)
            .then(res => res.json())
    }

    async getDropdown(): Promise<ResponseDropdown> {
        const params = {
            userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID),
            surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID)
        };

        const requestOptions: RequestInit = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }

        return await fetch(`${BASE_URL}${EndPoints.DROPDOWN}/${params.userId}/${params.surveyId}`, requestOptions)
            .then(res => res.json())
    }
}

export default new NavigateService();