import { Badge, Card, } from 'react-bootstrap';
import SurveyStrings from '../../../utils/constants/Strings';
import './../../../assets/custom-styles/custom-styles.css';

type QuestionProps = {
    id?: string;
    questionText: string;
    description?: string;
    question?: JSX.Element;
    isMandatory?: boolean;
}

const QuestionLayout = (props: QuestionProps ) => {
    
    const { id, questionText, question, description, isMandatory } = props;
    
    return <>
    
    <div id={id || ''} className='d-flex justify-content-center w-100 my-5'>
        
        <Card className='d-flex width_80 cardShadow'>
            
            <Card.Header className='customHeaders p-4'>
            
                <div className='fs-5'>{questionText}</div>
                {description && <div className='mt-3 fs-6' dangerouslySetInnerHTML={{__html: description}}></div>}
                {isMandatory && <Badge bg='warning' text='primary'>{SurveyStrings.MANDATORY}</Badge>}
            
            </Card.Header>

            {question && <Card.Body className='p-3'>

                {question}

            </Card.Body>}

        </Card>

    </div>

    </>
};

export default QuestionLayout