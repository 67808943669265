import { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import QuestionConfiguration from "../../../models/Question-Configuration";

type InputProjectProps = {
    config: QuestionConfiguration;
    be_answer: string;
    answer: string;
    isReadOnly?: boolean;
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
    sendError: (config: QuestionConfiguration, errorStatus: boolean, formula: string) => void;
}

const InputProject = (prop: InputProjectProps) => {

    const { config, be_answer, isReadOnly, onUserAnswer, sendError } = prop;

    const [isInvalid, setIsInvalid] = useState<boolean>()

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value === '0' ? 0 : (parseInt(e.target.value) || undefined);
        value && value > 50 ? setIsInvalid(true) : setIsInvalid(false);
        onUserAnswer(config, value);
    }

    const formatValue = (value: any) => {
        const val = parseInt(value);
        if(val === 0) return 0;
        else if (isNaN(val)) return '';
        else return val;
    };

    useEffect(() => {
        if(isInvalid !== undefined) sendError(config, !isInvalid, 'projectsError');
    }, [isInvalid, config, sendError]);


    return <>
        {config.lines?.map((line, index) => {
            return <InputGroup key={`${line}${index}`} hasValidation>
                <Form.Control
                    type='text'
                    value={formatValue(be_answer)}
                    onChange={handleChange}
                    isInvalid={isInvalid}
                    disabled={isReadOnly} 
                />
                <Form.Control.Feedback type="invalid">
                    Inserire un numero compreso fra 0 e 50
                </Form.Control.Feedback>
            </InputGroup>
        })}

    </>
}

export default InputProject