import {Spinner} from 'react-bootstrap';


const ProgressSpinner = (props: {message: string}) => {
    const {message} = props;

    return <>
    
        <div className='p-5 d-flex justify-content-center vh-100' >
            
            <div className='row justify-content-center align-items-center'>
                
                <div className='row align-items-center justify-content-center'>
                    
                    <h2 className='text-primary'>{message}</h2>
                    <Spinner variant='primary' animation='border' />
                    
                </div>

            </div>

        </div>
    </>
}

export default ProgressSpinner;