import SectionHeader from "../section-header/section-header";

import SectionConfiguration from "../../../models/Section-Configuration";
import { QuestionAnswer, MatrixAnswers } from "../../../models/Answers-Configuration";
import OperationsModel from "../../../models/Operations";

import SectionParser from "../../../utils/section-parser";


type SectionContainerProps = {
    operations: OperationsModel,
    answers: (MatrixAnswers | QuestionAnswer)[],
    configuration: SectionConfiguration,
    isReadOnly?: boolean,
    subSections?: SectionConfiguration[],
    preventNavigation?: boolean
    updateSessionAnswers: (answers: Map<string, MatrixAnswers | QuestionAnswer>) => void
    setError: (constraintsRespected: boolean) => void
    saveProjectAnswer: (projectId: string) => void
}
function SectionContainer(props: SectionContainerProps) {
    const { operations, configuration, answers, subSections, preventNavigation, updateSessionAnswers, setError, saveProjectAnswer, isReadOnly = false } = props;


    return (
        <>
            {configuration &&
                <div>
                    <SectionHeader
                        title={configuration.section.title}
                        description={configuration.section.description}
                        links={true}
                    />

                    <SectionParser
                        preventNavigation={preventNavigation}
                        operations={operations}
                        answers={answers}
                        isReadOnly={isReadOnly}
                        section={configuration}
                        subSections={subSections}
                        updateSessionAnswers={updateSessionAnswers}
                        setError={setError}
                        saveProjectAnswer={saveProjectAnswer}
                    />
                </div>}
        </>
    )
};

export default SectionContainer