import React from 'react';
import AppRoute from '../../../utils/constants/AppRoute';
import SurveyStrings from '../../../utils/constants/Strings';
import SessionService from '../../../services/Session-Service';
import SessionStorage from '../../../utils/constants/SessionStorage';

type SurveyToastProps = {
    first_message: string;
    second_message: string;
    link: boolean;
};

const SurveyErrorToast = (props: SurveyToastProps): JSX.Element => {
    const { first_message, second_message, link } = props;

    return <>
        <div className='w-100 d-flex justify-content-center'>
            <div className='w-75'>
                <p>{first_message}</p>
                <p className='mb-3'>{second_message}</p>
                {link &&
                    <a
                        className='toast__link'
                        href={`${AppRoute.SURVEY}${AppRoute.SUPPORT}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID) ?? '_'}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID) ?? '_'}`}
                       
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="white"
                            className="bi bi-box-arrow-up-right me-2"
                            viewBox="0 0 16 16"
                        >
                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                        </svg>

                        <b> {SurveyStrings.SUPPORT_GO} </b>
                    </a>
                }
                <p className='mt-3'><small><i>{SurveyStrings.TOAST_DISMISS}</i></small></p>
            </div>
        </div>
    </>

}

export default SurveyErrorToast;