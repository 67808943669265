import { Col, Container, ProgressBar, Row } from "react-bootstrap"
import { SectionProgressModel } from "../../../models/Section-Progress"
import SurveyStrings from "../../../utils/constants/Strings"
import SectionHeader from "../../section-components/section-header/section-header"

interface SectionProgressProps {
    progress?: SectionProgressModel[]
}

const SectionProgress = (props: SectionProgressProps) => {

    const { progress } = props

    return <>
        <SectionHeader
            title={SurveyStrings.SECTION_SUBMIT_PROGRESS}
            links={false}
        />

        {progress?.filter((prog) => prog.sectionId !== 'File').sort((firstElem, secondElem) => { return firstElem.position - secondElem.position }).map(entry => {
            return <div key={entry.sectionId} className="d-flex justify-content-center w-100">
                <div className="text-start width_80 my-2">
                    <Container>
                        <Row>
                            <Col>
                                {`${entry.sectionLabel}`}
                            </Col>
                            <Col>
                                <ProgressBar animated now={entry.value} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        })}
    </>
}
export default SectionProgress