export enum EndPoints {
    TICKET = 'ticket',
    SURVEY = 'survey',
    USER = 'user',
    ANSWERS = 'answers',
    SAVE = 'save',
    CLOSE = 'close',
    FINAL_CHECK = 'finalCheck',
    PROGRESS = 'progress',
    TEMPLATE = 'template',
    DROPDOWN = 'dropdown',
    FAQ = 'faq',
    WALKTHROUGH = 'guida.pdf',
    UPLOAD = 'upload'
}