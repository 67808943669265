import React from 'react';

import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import '../../../assets/custom-styles/custom-styles.css';

import QuestionConfiguration from '../../../models/Question-Configuration';

type SingleOptionProps = {
    config: QuestionConfiguration;
    be_answer?: string;
    disabledAnswer?: string[]
    //set to true to disable in recap
    isReadOnly?: boolean;
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
}

const SingleOption = (props: SingleOptionProps) => {
    const {
        config,
        onUserAnswer,
        be_answer,
        disabledAnswer,
        isReadOnly,
    } = props;


    const handleChange = (val: string) => {
        let newAnswers: string[] = [];

        if (be_answer?.includes(val)) {
            newAnswers = [];
        } else {
            newAnswers = [val];
        }

        onUserAnswer(config, newAnswers);
    };

    return <>

        <ToggleButtonGroup
            className='singleOptionGroup'
            type='radio'
            name={config.question}
            defaultValue={be_answer}
            onChange={handleChange}
        >

            {config.answers?.map((entry, index) => {

                return <React.Fragment key={`${entry}-index-key`}>

                    <div className="singleOptionGroup btn-group" data-toggle={config.tooltip && config.tooltip[index] ? 'tooltip' : ''} title={config.tooltip && config.tooltip[index] ? config.tooltip[index] : ''}>
                        <ToggleButton
                            disabled={(disabledAnswer?.includes(entry)) || isReadOnly}
                            id={`${entry}-${config.question}-${index}`}

                            className={
                                disabledAnswer?.includes(entry)
                                    ? 'singleOption my-1 p-3 rounded text-start disabledOption'
                                    : 'singleOption my-1 p-3 rounded text-start'
                            }
                            variant={
                                be_answer?.includes(entry)
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            value={entry}
                            onClick={() => handleChange(entry)}
                        >
                            {entry}
                        </ToggleButton>
                    </div>

                </React.Fragment>
            })}

        </ToggleButtonGroup>
    </>
}

export default SingleOption;