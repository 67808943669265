import Ticket from "../models/Ticket";
import { EndPoints } from "../utils/constants/EndPoints";
import { BASE_URL } from '../utils/constants/environment';
import SessionService from "./Session-Service";
import SessionStorage from "../utils/constants/SessionStorage";
class SupportService {

    async sendTicket(data: Ticket) {
        
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'content-type': 'Application/json',
                //'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            body: JSON.stringify(data)
        };

        return await fetch(`${BASE_URL}${EndPoints.TICKET}`, options)

    };

    //DEV ONLY : reopen closed survey
    async reopenSurvey() {
        const userId = SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID);
        const surveyId = SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID);
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'content-type': 'Application/json',
            }
        };
        return await fetch(`/survey/reopen/${userId}/${surveyId}`, options)
    }
}

export default new SupportService();