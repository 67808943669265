enum QuestionTypes {
    S_CHECKBOX = 'checkbox_single_choice',
    RADIO = 'radio',
    SINGLE_DATE = 'single_date',
    GROUP_DATE = 'group_date',
    FREE_TEXT = 'free_text',
    NUMBER ='number',
    PERCENTAGE = 'percentage',
    UPLOAD = 'upload',
    SINGLE_OPTION = 'single_option',
    GROUP_OPTION = 'group_option',
    PROJECT = 'project',
    FILE = 'file',
    SUB_HEADER = 'sub_header',
    RADIO_PERCENTAGE = 'radio_percentage',
    DOUBLE_RADIO = 'double_radio'
}

export default QuestionTypes;