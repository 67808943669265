const ErrorMessages: {[key:string]: string} =  {
     minComparison : 'I valori inseriti non possono essere superiori ai valori della riga precedente',
     minValueRegione: "La dimensione economica minima del progetto deve essere uguale o superiore a 200.000€",
     minValuePac: "La dimensione economica minima del progetto deve essere uguale o superiore a 300.000€",
     minValuePal: "La dimensione economica minima del progetto deve essere uguale o superiore a 150.000€",
     projectsError: 'Inserire un numero compreso fra 0 e 50',
     twoValueComparison: 'Il valore inserito nella seconda casella deve essere maggiore del valore inserito nella prima',
     twoValueComparisonInverted: "Il valore inserito nella seconda casella deve essere minore del valore inserito nella prima",
     sumDissect: 'Se sono stati forniti i valori di dettaglio per "Servizi a cittadini" e "Servizi alle Imprese" è necessario che la loro somma corrisponda al totale inserito.',
     sumDissectAlternate: 'Se sono indicati i valori di dettaglio per "Servizi a cittadini" e "Servizi alle Imprese" è necessario che la loro somma corrisponda al totale inserito nella prima colonna.'
};

export default ErrorMessages