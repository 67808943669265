import {Modal, Spinner} from 'react-bootstrap';
import SurveyStrings from '../../../utils/constants/Strings';

type ModalProps = {
    downloading: boolean;
    downloadMessage: string;
}
function DownloadModal(props: ModalProps) {

    const { downloading, downloadMessage} = props;
   
   return <>

        <Modal
            show={downloading}
        >

            <div className='pdf-modal'>

                <h6 className='text-center'>{downloadMessage}</h6>
                <div className="d-flex justify-content-center my-5">
                    <Spinner variant="primary" animation="grow" />
                </div>

                <p
                    className='px-5'
                >
                    <small>
                        {SurveyStrings.DOWNLOAD_WARNING}
                    </small>
                </p>

            </div>

        </Modal>
    </>
}

export default DownloadModal;