import "./App.scss";

//LIBRARIES
import { isIE } from "react-device-detect";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//COMPONENTS
import LoginForm from "./components/pages/login-form/login-form";
import RecapContainer from "./components/survey/recap-container/recap-container";
import SectionSubmit from "./components/survey/section-submit/section-submit";
import SurveyContainer from "./components/survey/survey-container/survey-container";
import FaqPage from "./components/pages/faq-page/faq-page";
import SupportForm from "./components/pages/support-form/support-form";
import LinkError from "./components/pages/link-error/link-error";
import SurveyToast from "./components/crossapp-utils/toast-container/toast-container";

//UTILS ASSETS && CONSTANTS
import AppRoute from "./utils/constants/AppRoute";
import IeWarning from "./components/crossapp-utils/IE-warning/ie-warning";


function App() {


  return <>
    {isIE && <IeWarning />}

    {!isIE &&
      <Router>
        <div className="App">
          <SurveyToast />
          <Routes>
            <Route path={AppRoute.LOGIN} element={<LoginForm />} />
            <Route path={AppRoute.FAQ} element={<FaqPage />} />
            <Route path={`${AppRoute.SURVEY}${AppRoute.SUPPORT}/:surveyId/:userId`} element={<SupportForm />} />
            <Route path={`${AppRoute.SURVEY}/:sectionId`} element={<SurveyContainer startPage={0} />} />
            <Route path={AppRoute.SUBMIT} element={<SectionSubmit />} />
            <Route path={AppRoute.RECAP} element={<RecapContainer />} />
            <Route path={AppRoute.LINK_ERROR} element={<LinkError />} />
            <Route path={AppRoute.LINK} element={<LoginForm />} />
          </Routes>
        </div>
      </Router>
    }
  </>
}

export default App;
