import { useState } from "react"
import QuestionConfiguration from "../../../models/Question-Configuration";
import DatePicker from 'react-datepicker';
import { Button, Col, Row } from "react-bootstrap";


type SingleDateProps = {
    config: QuestionConfiguration;
    be_answer: string;
    isReadOnly?: boolean;
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
    minDate?: boolean
}


const SingleDate = (prop: SingleDateProps) => {

    const { config, be_answer, isReadOnly, minDate, onUserAnswer } = prop;

    //const [isValid, setIsValid] = useState<boolean>();

    const userDate = be_answer ? new Date(be_answer) : undefined;

    const [value, setValue] = useState<Date | undefined>(userDate);

    const handleAnswer = (date: Date) => {
        if (date) {
            const dateString = date.toDateString()
            setValue(date)
            onUserAnswer(config, dateString)
        }
    }

    const deleteDate = () => {
        setValue(undefined)
    }

    const thisYear: number = new Date().getFullYear()


    return <>
        <Row className='my-2 d-flex align-items-center'>
            <Col xs={4} md={3} className='text-start'>
                <DatePicker
                    disabled={isReadOnly}
                    className='form-control'
                    selected={value}
                    onChange={handleAnswer}
                    showYearPicker
                    dateFormat="yyyy"
                    maxDate={new Date(3000, 0, 1)}
                    minDate={minDate ? new Date(thisYear, 0, 1) : undefined}
                />
            </Col>
            <Col xs={3} className='text-start'>
                <Button
                    size='sm'
                    className='rounded-0'
                    onClick={deleteDate}>X
                </Button>
            </Col>
        </Row>
    </>
}

export default SingleDate