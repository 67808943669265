import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { DateTime } from "luxon";
import QuestionConfiguration from '../../../models/Question-Configuration';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
registerLocale("it", it);


type GroupDateProps = {
    config: QuestionConfiguration;
    be_answer: string[];
    isReadOnly?: boolean;
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
}

enum DatePosition {
    FIRST = 'first',
    SECOND = 'second'
}

const GroupDate = (prop: GroupDateProps) => {

    const { config, be_answer, isReadOnly, onUserAnswer } = prop;

    const [firstAnswer, setFirstAnswer] = useState<Date | undefined>(be_answer[0] && be_answer[0].length > 0  ? new Date(be_answer[0]) : undefined);
    const [secondAnswer, setSecondAnswer] = useState<Date | undefined>(be_answer[1] && be_answer[1].length > 0  ? new Date(be_answer[1]) : undefined);

    const handleMinDate = () => {
        const dateLimit = new Date(2022, 9, 1);
        if (firstAnswer && DateTime.fromJSDate(firstAnswer) > DateTime.fromJSDate(dateLimit)) {
            return firstAnswer
        } else {
            return dateLimit
        }
    }


    const handleDates = (position: DatePosition, value?: Date) => {
        switch(position) {
            case DatePosition.FIRST: {
                if(value) {
                    setFirstAnswer(value);
                    onUserAnswer(config, [value.toDateString(), secondAnswer?.toDateString() || '']);
                } else {
                    setFirstAnswer(undefined);
                    onUserAnswer(config, ['', secondAnswer?.toDateString() || '']);
                }
                break
            }
            default: {
                if(value) {
                    setSecondAnswer(value);
                    onUserAnswer(config, [firstAnswer?.toDateString() || '', value.toDateString()])
                } else {
                    setSecondAnswer(undefined);
                    onUserAnswer(config, [firstAnswer?.toDateString() || '', '']);
                }
            }
        }
    }

    return <>
        <Row className='d-flex align-items-center'>
            <Col md={6}>
                <Row className='my-2 d-flex align-items-center'>
                    <Col md={4} className='text-start' >Data inizio:</Col>
                    <Col xs={8} md={5}>
                        <div className='text-start'>
                            <DatePicker
                                className='form-control'
                                selected={firstAnswer}
                                onChange={(newDate: Date) => handleDates(DatePosition.FIRST, newDate)}
                                locale='it'
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                maxDate={secondAnswer}
                                disabled={isReadOnly}
                            />
                        </div>
                    </Col>
                    <Col xs={4} md={3} className='text-start'>
                        <Button
                            size='sm'
                            className='rounded-0'
                            onClick={(e:any) => handleDates(DatePosition.FIRST)}>X</Button>
                    </Col>
                </Row>
            </Col>
            <Col md={6}>
                <Row className='my-2 d-flex align-items-center'>
                    <Col md={4} className='text-start'>Data fine:</Col>
                    <Col xs={8} md={5}>
                        <div className='text-start'>
                            <DatePicker
                                className='form-control'
                                selected={secondAnswer}
                                onChange={(newDate: Date) => handleDates(DatePosition.SECOND, newDate)}
                                locale='it'
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                minDate={handleMinDate()}
                                disabled={isReadOnly}
                            />
                        </div>
                    </Col>
                    <Col xs={4} md={3} className='text-start'>
                        <Button
                            size='sm'
                            className='rounded-0'
                            onClick={(e:any) => handleDates(DatePosition.SECOND)}>X</Button>
                    </Col>
                </Row>
            </Col>
        </Row >
    </>
}

export default GroupDate