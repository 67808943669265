import React, { useEffect, useState } from 'react';
//LIBS
import { Button, FormSelect, Pagination } from 'react-bootstrap';

//COMPONENTS
import QuestionLayout from '../components/survey/question-layout/question-layout';
import SingleOption from '../components/question-components/single-option/single-option';
import Checkbox from '../components/question-components/checkbox/checkbox';
import RadioMatrix from '../components/question-components/radio/radio';
import SingleDate from '../components/question-components/single-date/single-date';
import GroupDate from '../components/question-components/group-date/group-date';
import MatrixContainer from '../components/question-components/matrix/matrix-container';
import FreeText from '../components/question-components/free-text/free-text';
import InputNumber from '../components/question-components/input-number/input-number';
import GroupOption from '../components/question-components/group-option/group-option';
import FileUploader from '../components/question-components/file-uploader/file-uploader';
import InputProject from '../components/question-components/input-project/input-project';
import InputPercentage from '../components/question-components/input-percentage/input-percentage';
import RadioPercentage from '../components/question-components/radio-percentage/radio-percentage';

//MODELS
import QuestionConfiguration, { CellTypes, ConfigTypes, MatrixCallbackValues, MatrixCell, MatrixColumnConfig } from '../models/Question-Configuration';
import { QuestionAnswer, MatrixAnswers } from '../models/Answers-Configuration';
import { MatrixConfiguration } from '../models/Question-Configuration';
import SectionConfiguration from '../models/Section-Configuration';
import OperationsModel from '../models/Operations';

//UTILS && CONSTANTS
import QuestionTypes from './constants/Question-Types';
import ErrorMessages from '../utils/constants/ErrorMessages';
import { matrixOuterEnabler, matrixOperationParser, matrixTriggerConstraint } from '../utils/matrixutils';
import DoubleRadio from '../components/question-components/double-radio/double-radio';
import SurveyStrings from './constants/Strings';

type SectionProps = {
    operations: OperationsModel,
    answers: (MatrixAnswers | QuestionAnswer)[],
    section: SectionConfiguration,
    isReadOnly?: boolean,
    subSections?: SectionConfiguration[],
    preventNavigation?: boolean

    updateSessionAnswers: (answers: Map<string, MatrixAnswers | QuestionAnswer>) => void;
    setError: (constraintsRespected: boolean) => void;
    saveProjectAnswer: (projectId: string) => void
}

const SectionParser = (props: SectionProps) => {

    const { operations, section, answers, isReadOnly, subSections, preventNavigation, updateSessionAnswers, setError, saveProjectAnswer } = props;

    //WORKING VARIABLES
    const [sectionQuestions, setSectionQuestions] = useState<(QuestionConfiguration | MatrixConfiguration)[]>([...section.questions]);
    const [answersStorage, setAnswersStorage] = useState<(MatrixAnswers | QuestionAnswer)[]>(answers);
    const [sessionAnswers, setSessionAnswers] = useState<Map<string, MatrixAnswers | QuestionAnswer>>(new Map());
    const [disableNext, setDisableNext] = useState(false);
    const [disablePrev, setDisablePrev] = useState(false);


    //toggle child question visibility
    const toggleChild = (parentId: string, value: any, clonedAnswers: (MatrixAnswers | QuestionAnswer)[]) => {

        const questions = sectionQuestions;
        const answers = clonedAnswers;
        const parent = { ...questions.find(entry => entry.id === parentId) } as QuestionConfiguration;

        if (parent.type === QuestionTypes.S_CHECKBOX || parent.type === QuestionTypes.RADIO) {
            /*
                * Checkbox and Radio child toggler, relies on the triggerObj 
                * to enable/disable child questions, also works with multiple possible answers to the same line
            */
            parent.children?.forEach(child => {
                const output = Object.keys(value).map(key => {

                    if (child.triggerObj && child.triggerObj[key]) {

                        const condition = child.triggerObj[key];

                        const source = value[key];
                        const outputTrigger = condition?.some((c: string) => source.includes(c));
                        return outputTrigger;
                    } else {
                        return undefined
                    }
                });

                const outputShow = output.some(o => o === true);
                questions.forEach(question => {
                    if (question.id === child.id) {
                        const targetAnswer = answers.find(q => q.id === question.id);
                        if (targetAnswer) {
                            targetAnswer.show = outputShow;
                            saveDiff(targetAnswer);
                        }
                    }
                });
            })

        } else if (parent.type === QuestionTypes.DOUBLE_RADIO) {
            /*
                * DoubleRadio only child toggler: answers are stored as {[key: string ]: string} array
                * This methods flattens the array into a single object (flatVal) with a sting array per key
                * then iterates the parent question's children and checks if flatVal's values for the
                * corresponding child includes the right answer, thus enabling or disabling the optional question
            */

            const val: { [key: string]: string }[] = Object.values(value);
            const flatVal: { [key: string]: string[] } = {};
            Object.keys(val[0]).forEach(key => {
                flatVal[key] = [val[0][key] || '', val[1][key] || ''];
            })
            parent.children?.forEach(child => {

                const output = Object.keys(flatVal).map(key => {
                    if (child.triggerObj && child.triggerObj[key]) {
                        const condition = child.triggerObj[key];
                        const source = flatVal[key];

                        const outputTrigger = condition?.some((c: string) => source.includes(c));
                        return outputTrigger
                    } else {
                        return undefined
                    }
                });

                const outputShow = output.some(o => o === true);

                questions.forEach(question => {
                    if (question.id === child.id) {

                        const targetAnswer = answers.find(q => question.config_type === 'matrix' && "matrix_id" in q ? q.matrix_id === question.id : q.id === question.id);

                        if (targetAnswer) {
                            targetAnswer.show = outputShow;
                            saveDiff(targetAnswer);
                        }
                    }
                })
            })

        } else {
            /*
                * All other questions: iterates the parents' children and checks wheter the parent's answer contains
                * the matching trigger to enable the child, the multiple conditions in if(targetAnswer) match the
                * multiple possible formats in which each question component stores its answers
            */
            parent.children?.forEach(child => {
                
                    questions.forEach(question => {
                        if (question.id === child.id) {
                            const targetAnswer = answers.find(q => question.config_type === ConfigTypes.QUESTION ? q.id === question.id : ("matrix_id" in q && q.matrix_id === question.id));

                            if (targetAnswer) {
                                if (Array.isArray(child.trigger)) {

                                    targetAnswer.show = child.trigger?.includes(value) || child.trigger?.includes(value.toString());
                                    saveDiff(targetAnswer);
                                } else if (Array.isArray(value)) {
                                   
                                    if(child.inner_parent){
                                        const innerParent = {...child.inner_parent};
                                        const innerAnswer = {...answers.find(q => q.id === innerParent.id)} as QuestionAnswer;                          
                                        targetAnswer.show = value.includes(child.trigger) && innerAnswer.value.includes(child.inner_parent.trigger);
                                        saveDiff(targetAnswer);
                                    } else {
                                        targetAnswer.show = value.includes(child.trigger);
                                        saveDiff(targetAnswer);
                                    }
                                } else if (!Array.isArray(child.trigger)) {
                                        targetAnswer.show = child.trigger === value.toString();
                                        saveDiff(targetAnswer);
                                }
                            } 
                        }
                    })
                    return child;
              
            })
        }
        return answers

    };


    //used by matrix to show error status if constraint is not respected
    const setQuestionError = (config: QuestionConfiguration | MatrixConfiguration, respected: boolean, formula: string) => {
        const update = answersStorage;

        const question = update.find(entry => config.config_type === ConfigTypes.MATRIX ? ("matrix_id" in entry && entry.matrix_id === config.id) : entry.id === config.id);
        if (question) {
            question.questionError = {
                error: !respected,
                errorMessage: ErrorMessages[formula]
            }

        };
        setError(respected);
        setAnswersStorage(update);
    };

    /*
        * OUTER DISABLER 
        * Dynamically disables an option in a single_option question based on the answer
        * chosen in another single_option question, as seen in Section A 
        * between Question A02 (Enabler) and Question A03 (Slave)
    */
    const outerDisabler = (targetId: string, values: any, answers: (MatrixAnswers | QuestionAnswer)[]) => {

        const target = answers.find(entry => entry.id === targetId) as QuestionAnswer;
        if (target) target.disabledAnswers = values;

        //SAVE DIFF
        setSessionAnswers(sessionAnswers => new Map([...sessionAnswers, [target.id, target]]))
        return answers
    };

    /* 
        * MATRIX ACTION TRIGGER 
        * Takes a MatrixCell (passed along by matrixStoreAnswers) that contains a trigger
        * Uses the trigger to find the destination cell (in the same or other question)
        * Then collects the values in the destination cell operation input array and sends them to
        * the matrixOperationParses (in matrix utils), the returned value is sent to storeAnswers to the destination cell
    */
    const matrixTriggerAction = (source: MatrixCell) => {

        source.trigger?.forEach(trigger => {
            const destinationQuestion = sectionQuestions.find(entry => entry.id === trigger.question_id) as MatrixConfiguration;
            const destinationColumn = destinationQuestion.columns.find(col => col.column_id === trigger.column_id) as MatrixColumnConfig;
            const destinationCell = destinationColumn.column.find(cel => cel.cell_id === trigger.cell_id);

            const values: any[] = [];
            destinationCell?.operation?.inputs?.forEach(input => {
                const a = { ...answersStorage.find(answer => 'matrix_id' in answer && answer.matrix_id === input.question_id) } as MatrixAnswers;
                const c = a.columns.find(col => col.column_id === input.column_id);
                const val = c!.column.find(cell => cell.cell_id === input.cell_id)?.value;

                if (val && !isNaN(parseInt(val))) values.push(val);
                else values.push('0');
            })
            if (destinationCell && destinationCell.operation?.formula) {

                // eslint-disable-next-line no-new-func
                let operationResult: number = Math.round(Function('return ' + matrixOperationParser(values, destinationCell?.operation?.formula || '', operations))());

                if (operationResult !== Infinity) {
                    if (isNaN(operationResult)) operationResult = 0;
                    matrixStoreAnswers(destinationQuestion, operationResult, { colId: destinationColumn.column_id, cellId: destinationCell?.cell_id || '' })
                }
            }
        })
    }

    /*
        * MATRIX STORE ANSWERS
        * Matches a matrix question with its answer, finds the destination cell and 
        * should it have triggers or constraints triggers them 
    */
    const matrixStoreAnswers = (config: MatrixConfiguration, value: any, matrix: MatrixCallbackValues) => {
        const storage = answersStorage;
        const answers = [...storage];

        answers.map((answer) => {

            if (matrix) {

                if ("matrix_id" in answer && answer.matrix_id === config.id) {
                    const column = answer.columns.find(a => a.column_id === matrix.colId);

                    if (column) {

                        const cell = column.column.find(col => col.cell_id === matrix.cellId);
                        if (cell) {
                            cell.value = value.toString();
                            /* 
                                * CC01C and CC01D hardcoded bug patch
                                * The Matrix currently uses the fetched answers to render the component
                                * while outer enabler only updates the sessionAnswers(those that sent to the BE for saving)
                                * 
                                * This means that if an user enables and cc01c and cc01d and then goes in the next page
                                * when he comes back those questions will be disabled since fetched answers hasn't been updated
                                *
                                * POSSIBLE SOLUTION: rewrite the whole outer enabler method
                            */
                            if(config.id === 'cc01c' || config.id === 'cc01d') cell.type = CellTypes.INPUT;
                            //SAVE DIFF 
                            setSessionAnswers(sessionAnswers => new Map([...sessionAnswers, [answer.matrix_id, answer]]))
                        }
                        //SEARCH TRIGGERS AND CONSTRAINTS
                        if ("columns" in config) {
                            const col = config.columns.find(col => col.column_id === matrix.colId);

                            const trg = col?.column.find(cl => cl.cell_id === matrix.cellId);

                            if (trg && "trigger" in trg) matrixTriggerAction(trg);
                            if (trg && "constraint" in trg) matrixTriggerConstraint({ cell: trg, config: config, value: value, answersStorage: answersStorage, operations: operations, setQuestionError: setQuestionError })

                        }
                        return cell;
                    }
                }
            }
            return answer;
        });

        setAnswersStorage([...storage]);
    };

    /*
        * Called by OuterEnabler: everytime outerenabler disables a matrix row
        * it also resets its cells' values, this method retriggers the operations
        * stored in said matrix so that the compound values (eg totals) are re-elaborated
        * with the new reset values
    */
    const matrixRetriggerOperations = (matrixId: string) => {
        sectionQuestions.forEach(question => {
            if (question.id === matrixId && "columns" in question) {
                question.columns.forEach(column => {
                    column.column.forEach(cell => {
                        if ("trigger" in cell) {
                            matrixTriggerAction(cell)
                        }
                    })
                })
            }
        })
    }

    //NON-MATRIX callback for storing answers
    const storeAnswers = (config: QuestionConfiguration, value: any) => {
        let answers: (MatrixAnswers | QuestionAnswer)[];

        //CC01C and CC01D enabled by CC01B
        if (!config.outerEnabler) answers = answersStorage;
        else {

            const updated = matrixOuterEnabler({ enabler: config.outerEnabler, value: value, answers: answersStorage });
            //retrigger all operations and save diffs
            updated.diffs.forEach(entry => matrixRetriggerOperations(entry.value.matrix_id));
            updated.diffs.forEach(entry => saveDiff(entry.value));

            answers = updated.answers;
        };

        const target = answers.find(answer => answer.id === config.id) as QuestionAnswer;

        if (target.children) answers = toggleChild(target.id, value, answers);
        if (config.outerDisabler) answers = outerDisabler(config.outerDisabler, value, answers);

        answers.forEach((entry, index) => {
            if (entry.id === config.id) {
                entry.value = value;
                const clone: QuestionAnswer = entry;
                saveDiff(clone);
                return entry
            }
        })
        setAnswersStorage([...answers]);
    };

    //STORES ANSWERS PROVIDED BY USER FOR SAVING
    const saveDiff = (entry: QuestionAnswer | MatrixAnswers) => {
        if ("matrix_id" in entry) setSessionAnswers(sessionAnswers => new Map([...sessionAnswers, [entry.matrix_id, { ...entry }]]));
        else setSessionAnswers(sessionAnswers => new Map([...sessionAnswers, [entry.id, { ...entry }]]));
    }

    /*
        * PARSE CONFIGURATION
        * Takes a config object (Matrix or Question), finds its answer and returns the 
        * corresponding question component, to be rendered inside a question layout component.
        * Matrix answers match the corresponding question with the matrix_id field while question components
        * share the same id between config object and answer object, hence the first conditional
    */
    const buildQuestion = (entry: QuestionConfiguration | MatrixConfiguration): JSX.Element | undefined => {
        if (entry.config_type === ConfigTypes.MATRIX) {

            const conf = { ...entry } as MatrixConfiguration;
            const answer = { ...answersStorage.find(q => "matrix_id" in q && q.matrix_id === conf.id) } as MatrixAnswers;

            return <MatrixContainer config={conf} be_answer={answer} onUserAnswer={matrixStoreAnswers} readonly={isReadOnly} preventNavigation={preventNavigation} />;


        } else if (entry.config_type === ConfigTypes.QUESTION) {

            const conf = { ...entry } as QuestionConfiguration
            const answer = { ...answersStorage.find(q => q.id === conf.id) } as QuestionAnswer;

            switch (entry.type) {
                case QuestionTypes.SINGLE_OPTION: {
                    return <SingleOption config={conf} be_answer={answer.value} disabledAnswer={answer.disabledAnswers} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.S_CHECKBOX: {
                    return <Checkbox config={conf} be_answer={answer.value || {}} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.RADIO: {
                    return <RadioMatrix config={conf} be_answer={answer.value} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.SINGLE_DATE: {
                    return <SingleDate config={conf} be_answer={answer.value} minDate={conf.minDate} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.GROUP_DATE: {
                    return <GroupDate config={conf} be_answer={answer.value} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.FREE_TEXT: {
                    return <FreeText key={`${entry.id}-${answer.id}-key`} config={conf} be_answer={answer.value} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.NUMBER: {
                    return <InputNumber config={conf} be_answer={answer.value} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.PERCENTAGE: {
                    return <InputPercentage config={conf} be_answer={answer.value} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.GROUP_OPTION: {
                    return <GroupOption config={conf} be_answer={answer.value} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} />
                }
                case QuestionTypes.PROJECT: {
                    return <InputProject config={conf} answer={answer.value} be_answer={answer.value} onUserAnswer={storeAnswers} isReadOnly={isReadOnly} sendError={setQuestionError} />
                }
                case QuestionTypes.FILE: {
                    return <FileUploader config={conf} isReadOnly={isReadOnly} be_answer={answer.value} onUserAnswer={storeAnswers} />
                }
                case QuestionTypes.SUB_HEADER: {
                    return
                }
                case QuestionTypes.RADIO_PERCENTAGE: {
                    return <RadioPercentage config={conf} isReadOnly={isReadOnly} be_answer={answer.value || {}} onUserAnswer={storeAnswers} />
                }
                case QuestionTypes.DOUBLE_RADIO: {
                    return <DoubleRadio config={conf} isReadOnly={isReadOnly} be_answer={answer.value} onUserAnswer={storeAnswers} />
                }
                default: {
                    return <></>
                }
            }
        } else return <></>
    };

    //Invoked by the project selector
    const onProjectSelect = (e: any) => {
        const projectId = e.target.value;
        saveProjectAnswer(projectId);
    };

    const onProjectClick = (next: boolean) => {
        if (subSections) {
            const index = subSections.findIndex(entry => entry.section.id === section.section.id);
            if (next) {
                const projectId = subSections[index + 1].section.id
                saveProjectAnswer(projectId)
            } else {
                const projectId = subSections[index - 1].section.id
                saveProjectAnswer(projectId)
            }
        }
    }

    //check if project navigation is disabled
    useEffect(() => {
        if (subSections) {
            const projects = subSections.filter(entry => entry.section)
            const index = subSections.findIndex(entry => entry.section.id === section.section.id);
            if (index === projects.length - 1) setDisableNext(true)
            if (index === 0) setDisablePrev(true)
        }
    }, [subSections, section.section.id]);

    //Triggers a re-render everytime the parent component switches to a different section
    useEffect(() => {
        setSectionQuestions([...section.questions])
    }, [section]);

    //fire callback to pass answers on higher level
    useEffect(() => {

        updateSessionAnswers(sessionAnswers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionAnswers])

    /*
        * Question visibility is entirely basesd on the show key that is present in each 
        * answer object : the answer object is the only config object that the user gets to change
        * thus every visualization logic and every error status must be defined in it
    */

    return <>
        {
            section.questions.map((entry, i) => {
                let showValue;

                if (entry.config_type === ConfigTypes.MATRIX) {

                    showValue = answersStorage.find(a => "matrix_id" in a && a.matrix_id === entry.id)?.show;

                } else {

                    showValue = answersStorage.find(a => a.id === entry.id)?.show;

                };
                if (showValue === true) return <QuestionLayout
                    id={`${entry.id}-question-layout`}
                    key={`${entry.id}-${i}-question-layout`}
                    questionText={entry.question} description={entry.description}
                    question={buildQuestion(entry)}
                    isMandatory={entry.isMandatory} />;
                else return <React.Fragment key={`${entry.id}-${i}-hidden-question-layout`} />

            })
        }


        {subSections && subSections.length > 0 && !section.sub_sections &&
            <div className='d-flex justify-content-center'>
                <Pagination>
                    <Button
                        className='btn btn-secondary btn-sm me-3'
                        disabled={disablePrev || preventNavigation}
                        onClick={() => onProjectClick(false)}>{SurveyStrings.PREVIOUS_PROJECT}</Button>

                    <FormSelect
                        disabled={preventNavigation}
                        onChange={onProjectSelect}
                        value={subSections.find(entry => entry.section.id === section.section.id)?.section.id}
                    >
                        {subSections && subSections.map((p, i) => (
                            <option
                                key={`${p.section.id}-${i}`}
                                value={p.section.id}
                            >
                                Progetto {i + 1}
                            </option>
                        ))}
                    </FormSelect>
                    <Button
                        className='btn btn-secondary btn-sm ms-3'
                        disabled={disableNext || preventNavigation}
                        onClick={() => onProjectClick(true)}>{SurveyStrings.NEXT_PROJECT}</Button>

                </Pagination>

            </div>
        }
    </>


};

export default SectionParser