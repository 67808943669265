enum SessionStorage {
    SESSION_STORAGE_JWT_TOKEN = 'jwt_token',
    SESSION_STORAGE_USER_ID = 'userId',
    SESSION_STORAGE_SURVEY_ID = 'surveyId',
    SESSION_STORAGE_ANSWER_ID = 'answerId',
    SESSION_STORAGE_USER_OBJ = 'user-wink',
    LOCAL_STORAGE_USER_OBJ = 'local-user-wink',
    SESSION_STORAGE_PROGRESS = 'progress'
}

export default SessionStorage