import { Form } from 'react-bootstrap';

import QuestionConfiguration from '../../../models/Question-Configuration';

import '../../../assets/custom-styles/custom-styles.css';

type SingleCheckboxProps = {
    config: QuestionConfiguration;
    //set to true to disable in recap
    isReadOnly?: boolean;
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
    be_answer: {[key:string]: string[]}
}

const Checkbox = (props: SingleCheckboxProps) => {
    const { config, onUserAnswer, be_answer, isReadOnly} = props;

    const handleChange = (line: string, ans: string) => {
        let update = {...be_answer};
        if(update[ans]) {
            if(update[ans].includes(line)) update[ans] = update[ans].filter(entry => entry !== line);
            else update[ans].push(line);
        } else {
            update[ans] = [line]
        }
        onUserAnswer(config, update);
    };



    return <>
        <div className='d-none d-md-flex row'>
            <div className='col-md-2'>

            </div>

            <div className='col'>
                <div className='row'>

                    {config.answers?.map(entry =>
                        <div
                            key={`${entry}-checkbox-label`}
                            className='col'
                        >
                            {entry}
                        </div>
                    )}
                </div>

            </div>

        </div>


        {config.lines!.map((line: string, li: number) => {
            return (
                <div 
                    className='row py-3 matrixLine' 
                    key={`${line}-${li}-entry-key-checkbox`}
                >
                    <div className='row-sm-2 col-md-2 mt-2 mt-md-0 matrix__labels'> 
                        <span>{line}</span>
                    </div>

                    <div className='col'>
                        <div className='row matrix__boxes'>
                            {config.answers?.map((ans, ind) => {
                                return (
                                    <div
                                        className='col-sm row-md d-flex justify-content-sm-start justify-content-md-center mt-3 mt-md-0'
                                        key={`${ind}-${ans}-key-checkbox`}
                                    >

                                        <Form.Check
                                            disabled={isReadOnly}
                                            readOnly={isReadOnly}
                                            name={ans}
                                            type='checkbox'
                                            checked={(be_answer[ans] && be_answer[ans].includes(line)) || false}
                                            onChange={(e: any) => 
                                                handleChange(line, ans)
                                            }
                                        />
                                        <p className='d-md-none ms-2 text-align-center'>{ans}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )

        })}

    </>
}

export default Checkbox;