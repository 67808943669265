import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

//Components
import { Form, Row, Col } from 'react-bootstrap';
import QuestionLayout from '../../survey/question-layout/question-layout';
import SurveyFooter from '../../crossapp-utils/footer/footer';
import SurveyHeader from '../../crossapp-utils/header/header';
import { headerImage } from '../../../assets/images';
import HomeBox from '../home-box/home-box';
import ProgressSpinner from '../../crossapp-utils/progress-spinner/progress-spinner';

//Models && Utils
import { LinkBase64 } from '../../../models/Link';
import User from '../../../models/User';
import AppRoute from '../../../utils/constants/AppRoute';
import SessionStorage from '../../../utils/constants/SessionStorage';
import SurveyStrings from '../../../utils/constants/Strings';
import '../../../assets/custom-styles/custom-styles.css';

//Services
import SessionService from '../../../services/Session-Service';
import UserService from '../../../services/User-Service';
import SurveyErrorToast from '../../crossapp-utils/toast-error/survey-toast-error';

function LoginForm() {
    //HOOKS
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams()
    const navigate = useNavigate();

    //LOCAL CONFIG
    const [user, setUser] = useState<Partial<User>>({});
    const [fetched, setFecthed] = useState<boolean>(false);
    const search = searchParams.get("link")

    //INIT GET
    useEffect(() => {
        if (loading && search) {
            const replacedLink: string = search.replaceAll("abcde", "");
            const decoded: LinkBase64 = JSON.parse(atob(replacedLink));
            const userId: string = decoded.userId;
            const surveyId: string = decoded.surveyId;
            SessionService.setItem(SessionStorage.SESSION_STORAGE_USER_ID, userId);
            SessionService.setItem(SessionStorage.SESSION_STORAGE_SURVEY_ID, surveyId);
            
                UserService.getOneUser(userId)
                .then(res => {
                    SessionService.setObj(SessionStorage.SESSION_STORAGE_USER_OBJ, res.payload.user);
                    setUser(res.payload.user);
                    setFecthed(true);
                })
                .catch(err => {
                    console.log('Get user error :  ', err);
                    toast.error(
                        <SurveyErrorToast
                            link={true}
                            first_message={SurveyStrings.GET_USER_ERROR}
                            second_message={SurveyStrings.GET_USER_ERROR_LINE_TWO} />)
                })
                .finally(() => setLoading(false));

        } else if (!search) {
            navigate(AppRoute.LINK_ERROR);
        }

    }, [loading, search, navigate]);

    //LOGIN TO FIRST OR LAST LOGIN
    const login = () => {
        navigate(`${AppRoute.SURVEY}/section`);
    };

    //PREFILLED FORM
    const content: JSX.Element =

        <div className='container p-5 my-3 width_80'>

            <Form.Group as={Row} className='mb-3'>

                <Form.Label
                    column
                    sm='4'
                    lg='2'
                    className='d-flex text-align-start'
                >
                    {SurveyStrings.NAME}
                </Form.Label>

                <Col sm='8' lg='10'>

                    <Form.Control
                        name={user.name}
                        type='text'
                        defaultValue={user.name}
                        disabled={true}
                    />

                </Col>

            </Form.Group>

            <Form.Group as={Row} className='mb-3'>

                <Form.Label
                    column
                    sm='4'
                    lg='2'
                    className='d-flex text-align-start'
                >
                    {SurveyStrings.SURNAME}
                </Form.Label>

                <Col sm='8' lg='10'>

                    <Form.Control
                        name={user.surname}
                        type='text'
                        defaultValue={user.surname}
                        disabled={true}
                    />

                </Col>

            </Form.Group>

            <Form.Group as={Row} className='mb-3'>

                <Form.Label
                    column
                    sm='4'
                    lg='2'
                    className='d-flex text-align-start'
                >
                    {SurveyStrings.ROLE}
                </Form.Label>

                <Col sm='8' lg='10'>

                    <Form.Control
                        name={user.institutionName}
                        type='text'
                        defaultValue={user.institutionName}
                        disabled={true}
                    />

                </Col>

            </Form.Group>

            <Form.Group as={Row} className='mb-3'>

                <Form.Label
                    column
                    sm='4'
                    lg='2'
                    className='d-flex text-align-start'
                >
                    {SurveyStrings.MAIL}
                </Form.Label>

                <Col sm='8' lg='10'>

                    <Form.Control
                        name={user.email}
                        type='text'
                        defaultValue={user.email}
                        disabled={true}
                    />

                </Col>

            </Form.Group>

        </div>




    return <>
        {loading && 
            <ProgressSpinner message={SurveyStrings.LOADING} />
        }
        
        {!loading && 
            <>
                <SurveyHeader images={[headerImage]} />

                <HomeBox
                    text={SurveyStrings.HOMEBOX_HEADING}
                    descriptionTitle={SurveyStrings.HOMEBOX_TITLE}
                    descriptionBody={SurveyStrings.HOMEBOX_DESCRIPTION}
                />

                <QuestionLayout 
                    questionText={SurveyStrings.DATA} 
                    question={content} 
                />

                {fetched && <SurveyFooter btn_forward={login} />}
            </>  
        }
    </>
}

export default LoginForm;