import SurveyFaqConfiguration from "../models/Survey-Faq-Configuration";
import { BASE_URL } from "../utils/constants/environment";
import { EndPoints } from "../utils/constants/EndPoints";
import SessionService from "./Session-Service";
import SessionStorage from "../utils/constants/SessionStorage";


type SurveyFaqResponse = {
    message: string;
    payload: {faq: SurveyFaqConfiguration};
    status: number;
}
//SERVICE FOR RETRIEVING FAQS AND WALKTHOUGHS
class ConfigService {

    async getFaqs(): Promise<SurveyFaqResponse> {
        const params = {
            userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID),
            surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID)
        };

        return await fetch(`${BASE_URL}${EndPoints.FAQ}/${params.surveyId}/${params.userId}`)
            .then(res => res.json())
    }

}

export default new ConfigService();