import React, { useState, useEffect } from 'react';

//LIBS
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Card, Dropdown, Button } from 'react-bootstrap';

//MODELS
import SurveyFaqConfiguration from "../../../models/Survey-Faq-Configuration";

//COMPONENTS
import FaqCard from './faq-card';
import SurveyHeader from "../../crossapp-utils/header/header";
import SectionHeader from "../../section-components/section-header/section-header";

//UTILS AND CONSTANTS
import { headerImage } from "../../../assets/images";
import AppRoute from '../../../utils/constants/AppRoute';

//SERVICES
import ConfigService from '../../../services/Config-Service';
import ProgressSpinner from '../../crossapp-utils/progress-spinner/progress-spinner';

const FaqPage = () => {

    const navigate = useNavigate();
    const [data, setData] = useState<SurveyFaqConfiguration>({
        title: '',
        sections: [],
        forwardButton: '',
        backButton: '',
        homeLink: ''
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [showError,] = useState<boolean>(false);

    const pageChange = (page: number) => {

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0);
        setCurrentPage(page);
    };

    //Init get
    useEffect(() => {
        if (isLoading) {
            //fetch faqs
            ConfigService.getFaqs()
                .then(res => {
                    setData(res.payload.faq);
                    setIsLoading(false);
                    console.log(res.payload.faq)
                })
                .catch(err => {
                    toast.error(err.message);
                })
        }
    }, [isLoading]);

    //TODO: Make error card a dynamic component
    //TODO: Loader implementation

    return <React.Fragment>
        {
            showError &&
            <>
                <SurveyHeader images={[headerImage]} />

                <SectionHeader title={data.title} links={false} />

                <div className='w-100 d-flex my-5 p-5 justify-content-center container'>
                    <Card className='w-50 p-5'>
                        <p className='my-5'>Errore nel caricamento della pagina</p>

                        <Button className='btn-primary my-2 mx-5' onClick={(e: any) => window.location.reload()}>Riprova</Button>
                        <Button className='btn-primary my-2 mx-5' onClick={(e: any) => navigate(AppRoute.LOGIN)}>Torna alla home</Button>

                    </Card>
                </div>
            </>
        }
        {
            isLoading && !showError &&
            <ProgressSpinner message={'Caricamento in corso'} />
        }
        {
            !isLoading &&
            <>
                <SurveyHeader images={[headerImage]} />

                <SectionHeader title={data.title} links={false} />

                <div className='w-100 faq__container'>

                    <div className='width_80 row'>

                        <div className='d-flex justify-content-end me-0 pe-0'>
                            <Dropdown role='button'>

                                <Dropdown.Toggle id='faq-hamburger' className='faqToggle'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#025BB3" className="bi bi-list" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='faq__dropdown'>

                                    {data.sections && data.sections.length && data.sections.map(section =>
                                        <Dropdown.Item
                                            key={`${section.position}-${section.sectionTitle}-dropdown-item`}
                                            name={section.sectionTitle}
                                            className={currentPage === section.position ? 'dropdownCurrent' : 'dropdownItem'}
                                            onClick={(e: any) => setCurrentPage(section.position)}
                                        >
                                            {section.sectionTitle}
                                        </Dropdown.Item>
                                    )}

                                </Dropdown.Menu>

                            </Dropdown>
                        </div>
                    </div>

                    <div className='width_80 row'>


                        <div className='w-100 my-2 justify-content-center text-start'>
                            <Card className='p-3 border-0'>

                                <h4 className='text-muted'>
                                    {data.sections[currentPage].sectionTitle}
                                </h4>

                            </Card>

                            {data.sections[currentPage].faqs.map(f =>
                                <React.Fragment key={`${f.title}-faq-card-key`}>
                                    <FaqCard faq={f} />
                                </React.Fragment>

                            )}

                        </div>
                    </div>

                    <div className='width_80 row mb-5 pb-5'>




                        <div className='d-flex row'>

                            <div className='col-xs-12 col-md-6 d-flex justify-content-start'>

                                {currentPage !== 0 &&

                                    <Button
                                        className={'btn-primary navButton me-2'}
                                        onClick={(e: any) => pageChange(currentPage - 1)}
                                    >
                                        {data.backButton}
                                    </Button>

                                }

                            </div>

                            <div className='col-xs-12 col-md-6 d-flex justify-content-end'>

                                {currentPage !== (data.sections.length - 1) &&
                                    <Button
                                        className='btn-primary navButton'
                                        onClick={(e: any) => pageChange(currentPage + 1)}
                                    >
                                        {data.forwardButton}
                                    </Button>
                                }
                            </div>
                        </div>




                    </div>
                </div>
            </>
        }
    </React.Fragment>

}

export default FaqPage;