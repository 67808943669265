import QuestionTypes from '../utils/constants/Question-Types';

export enum ConfigTypes  {
    QUESTION = "question",
    MATRIX = "matrix"
}
interface BasicQuestionConfig {
    id: string;
    answers_id?: string;
    question: string;
    description?: string;
    position: number;
    conditional: boolean;
    type: QuestionTypes | string;
    
};

interface QuestionConfiguration extends BasicQuestionConfig {
    config_type: ConfigTypes.QUESTION | string;
    answers?: string[]; 
    children?: { 
        id: string; 
        trigger?: string | string[];
        inner_parent?: {
            id: string;
            trigger: string;
        };
        triggerObj?: Partial<TriggerObj>
    }[]; 

    outerEnabler?: OuterEnabler[];
    outerDisabler?: string;
    disabledAnswers?: string[];
    lines?: string[],
    radio_columns?: string[]
    tooltip?: string[];
    isMandatory?: boolean;
    minDate?: boolean
}

type TriggerObj = {[key:string]: string[]}
  

export type OuterEnabler = {
    question_id: string;
    key: string;
    row_index: number;
}

export interface MatrixOperation {
    inputs?: {
        position: number;
        question_id: string;
        column_id: string;
        cell_id: string
    }[],
    formula: string,
}
export interface MatrixCell {
    [key: string]: any
    position: number,
    cell_id: string,
    format: string,
    dynamicType?: boolean,
    type: string,
    trigger?: Trigger[],

    operation?: MatrixOperation,
    constraint?: MatrixOperation,
    noRender?: boolean,
    negative?: boolean,
    labelCell?: boolean,
}

export enum CellTypes  {
    INPUT = 'input',
    READONLY = 'readonly',
    SLAVE = 'slave'
}

export interface MatrixColumnConfig {
    [key: string]: string | number | boolean | MatrixCell[] ,
    position: number,
    column_id: string,
    label: string,
    column: MatrixCell[],
    prefilled: boolean
}
export interface MatrixConfiguration extends BasicQuestionConfig {
    config_type: ConfigTypes.MATRIX | string;
    rows: Row[];
    columns: MatrixColumnConfig[];
    show?: boolean;
    banner: Banners;
    isMandatory?: boolean
}
export interface MatrixCallbackValues {
    colId: string,
    cellId: string
}
export interface Row {
    label: string,
    value: string,
    position: number,
    perc?: boolean,
    tooltip?: string
}

export interface Trigger {
    question_id: string,
    column_id: string,
    cell_id: string
}

export interface MatrixInputs extends Trigger {
    position: number
}

export interface Banners {
    banner_one: string,
    banner_two?: string
}

export default QuestionConfiguration;
