import { Dropdown } from "react-bootstrap";
import '../../../assets/custom-styles/custom-styles.css';

type DropdownProps = {
    sections: {
        [key: string]: string | number,
        name: string,
        label: string,
        index: number
    }[],
    currentPage: string,
    buttonClick: (e: number, entry: string) => void,
    preventNav?: boolean,
}

const DropdownNav = (props: DropdownProps) => {
    const { sections, currentPage, preventNav, buttonClick } = props;

    return <>

        <div className=' d-flex w-100 justify-content-end my-5 pe-5'>
            <Dropdown role='button'>

                <Dropdown.Toggle id='hamburger dropdown' className='navButton'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {sections.map((entry: any, i: number) => 
                         <Dropdown.Item
                            disabled={preventNav}
                            key={entry.index}
                            className={currentPage !== entry.name ? 'dropdownItem' : 'dropdownCurrent'}
                            name={entry.name}
                            onClick={(e: any) => buttonClick(entry.index, entry.name)}
                        >
                            {entry.label}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>

            </Dropdown>
        </div>

    </>
};

export default DropdownNav