import React, { useState } from 'react';

import { Row, Col, FormControl, InputGroup } from 'react-bootstrap';

import QuestionConfiguration from "../../../models/Question-Configuration"


type RadioPercentageProps = {
    config: QuestionConfiguration;
    isReadOnly?: boolean;
    be_answer: { [key: string]: string };
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
}

const RadioPercentage = (props: RadioPercentageProps) => {

    const { config, isReadOnly, be_answer, onUserAnswer } = props;

    const [formValue, setFormValue] = useState<{ [key: string]: string }>(be_answer || {});

    const formatValue = (value: any) => {

        const val = parseInt(value);
        if (isNaN(val)) return ' ';
        else if (val <= 100) {
            const returnVal = val.toString();
            if (returnVal[0] === '0') {
                if (returnVal.length > 1) return returnVal.slice(1)
                else return '0';
            }
            else return returnVal;
        } else return ' ';

    };

    const handleChange = (e: any) => {
        const update = { [e.target.name]: e.target.value };
        setFormValue(update);
        onUserAnswer(config, update);
    };

    return <>

        {config.lines && config.lines.map(line =>

            <Row key={`${line}-percentage-radio-row`} className='mb-3'>
                <Col xs={6} className='text-start'>
                    {line}
                </Col>

                <Col xs={6}>
                    <InputGroup>
                        <FormControl
                            style={{ maxWidth: "200px" }}
                            name={line}
                            type='string'
                            min={0}
                            onWheel={(e: any) => e.target.blur()}
                            value={formatValue(formValue[line]) || ''}
                            onChange={handleChange}
                            disabled={isReadOnly}
                        />

                        <InputGroup.Text className='matrix__percLabel'>
                            %
                        </InputGroup.Text>

                    </InputGroup>
                </Col>
            </Row>

        )}

    </>
}

export default RadioPercentage