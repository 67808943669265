import { Form, InputGroup, Col, Row } from "react-bootstrap";
import QuestionConfiguration from "../../../models/Question-Configuration";

type InputPercentageProps = {
    config: QuestionConfiguration;
    isReadOnly?: boolean;
    be_answer: string | string[]
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
}

const InputPercentage = (props: InputPercentageProps) => {

    const { config, be_answer, isReadOnly, onUserAnswer } = props;


    const formatValue = (value: any) => {
        const val = parseInt(value);
        if (isNaN(val)) return ' ';
        else {
            if (val <= 100) {
                const returnVal = val.toString();
                if (returnVal[0] === '0') {
                    if (returnVal.length > 1) return returnVal.slice(1)
                    else return '0';
                }
                else return returnVal;
            } else return ' ';
        }
    };

    const handleMultiline = (value: string, index: number) => {
        
        if (Array.isArray(be_answer)) {
            const update = be_answer;
            update[index] = value;
            onUserAnswer(config, update);
        }
    }

    return <>
        {config.lines && config.lines.length > 0  && Array.isArray(be_answer) 
        
        ?
            config.lines?.map((line, index) => {
                return <InputGroup key={`${line}${index}`}>
                    <Row className='w-100 mb-2'>
                        <Col sm={4}>
                            <Form.Text className='text-dark float-start'>{line}</Form.Text>
                        </Col>
                        <Col sm={8}>
                            <InputGroup >
                                <Form.Control
                                    type='text'
                                    value={formatValue(be_answer[index])}
                                    onChange={(e: any) => handleMultiline(e.target.value, index)}
                                    disabled={isReadOnly} />
                            </InputGroup>
                        </Col>
                    </Row>
                </InputGroup>
            })


            :

            <InputGroup className='w-25' key={`${config.id}-input-group-key`}>

                <Form.Control
                    type='string'
                    min={0}
                    onWheel={(e: any) => e.target.blur()}
                    value={formatValue(be_answer)}
                    onChange={(e: any) => onUserAnswer(config, e.target.value)}
                    disabled={isReadOnly}
                />
                <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>

        }

    </>
}

export default InputPercentage;
