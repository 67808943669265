import { EndPoints } from '../utils/constants/EndPoints';
import { BASE_URL } from '../utils/constants/environment';
import SessionStorage from '../utils/constants/SessionStorage';
import SessionService from './Session-Service';

type ResponseTemplate = {
    message: string,
    payload: {
        template: {
            id: string;
            user_role: string;
            encoded_xls: string;
        }
    },
    status: number
}

class TemplateService {

    async getOneTemplate(role: string): Promise<ResponseTemplate> {

        const user: RequestInit = {
            method: 'GET',
            headers: {
                'content-type': 'Application/json',
                //'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },

        };

        return await fetch(`${BASE_URL}${EndPoints.TEMPLATE}/${role}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID)}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID)}`, user)
            .then(res => res.json())
    }


}

export default new TemplateService();