import React, { useState } from 'react';

import { Form } from 'react-bootstrap';

import QuestionConfiguration from '../../../models/Question-Configuration';

import '../../../assets/custom-styles/custom-styles.css';

type SingleCheckboxProps = {
    config: QuestionConfiguration;
    be_answer: {[key:string]: string[]}
    //set to true to disable in recap
    isReadOnly?: boolean;
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;

}

const RadioMatrix = (props: SingleCheckboxProps) => {

    const { config, be_answer, onUserAnswer, isReadOnly } = props;

    const [answer, setAnswer] = useState<{[key:string]: string[]}>(be_answer || {});

    const handleChange = (line: string, ans: string) => {
        const update = {...answer};
        if(update[line] && update[line].includes(ans)) update[line] = [];
        else update[line] = [ans];
        
        //update[li] = check ? line + ' - ' + column : undefined;
        setAnswer(update);
        onUserAnswer(config, update);
    };

    return <>
        <div className='d-none d-md-flex row'>
            <div className='col-sm-2'>

            </div>

            <div className='col'>
                <div className='row'>

                    {config.answers?.map((entry, index) =>
                        <div
                            key={`${entry}-${index}-radio-label`}
                            className='col'
                        >
                            {entry}
                        </div>
                    )}
                </div>

            </div>

        </div>


        {config.lines!.map((line: string, li: number) => {
            return (
                <div 
                    className='row py-3 radioMatrix' 
                    key={`${li}-${line}-radio-div-key`}
                >
                    <div className='col-sm-2 col-md-2 mt-2 mt-md-0 matrix__labels'>
                        <span>{line} </span>
                    </div>

                    <div className='col'>
                        <div className='row matrix__boxes'>
                            {config.answers?.map((ans, ind) => {
                                return (
                                    <div
                                        className='col-sm row-md d-flex justify-content-sm-start justify-content-md-center mt-3 mt-md-0'
                                        key={`${ans}-${line}-${li}-${ind}-radio-key`}
                                    >
                                        <Form.Check
                                            disabled={isReadOnly}
                                            readOnly={isReadOnly}
                                            name={ans}
                                            type='checkbox'
                                            checked={(answer[line] && answer[line].includes(ans)) || false}
                                            onChange={(e: any) => {
                                                handleChange(line, ans)
                                            }}
                                        />
                                        <p className='d-md-none ms-2 text-align-center'>{ans}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )

        })}

    </>
}

export default RadioMatrix;