import React from "react"
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import QuestionConfiguration from "../../../models/Question-Configuration"

type GroupOptionProps = {
    config: QuestionConfiguration;
    be_answer: String[];
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
    //set to true to disable in recap
    isReadOnly?: boolean;
}


const GroupOption = (props: GroupOptionProps) => {

    const { be_answer, config, onUserAnswer, isReadOnly } = props;

    const handleChange = (val: string, e: any) => {
        e.preventDefault();
        const update = be_answer.includes(val) ? be_answer.filter(entry => entry !== val) : be_answer.concat(val);
        onUserAnswer(config, update);
    };

    return <>
        <ToggleButtonGroup
            className='singleOptionGroup'
            type='radio'
            name={config.question}
            defaultValue={be_answer}
            onChange={(value, e) => { handleChange(value, e) }}
        >
            {config.answers?.map((entry, index) => {

                return <React.Fragment key={`${entry}-index-key`}>
                    <div className="singleOptionGroup btn-group" data-toggle={config.tooltip && config.tooltip[index] ? 'tooltip' : ''} title={config.tooltip && config.tooltip[index] ? config.tooltip[index] : ''}>
                        <ToggleButton
                            disabled={isReadOnly}
                            id={`${entry}-${config.question}-${index}`}
                            className='singleOption my-1 p-3 rounded text-start'
                            variant={be_answer.includes(entry) ? 'primary' : 'outline-primary'}
                            value={entry}
                            onClick={(e) => { handleChange(entry, e) }}
                        >
                            {entry}
                        </ToggleButton>
                    </div>
                </React.Fragment>
            })}
        </ToggleButtonGroup>
    </>
}

export default GroupOption