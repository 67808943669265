import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

//LIBS
import { toast } from 'react-toastify';
import $ from 'jquery';

//COMPONENTS
import SurveyHeader from '../../crossapp-utils/header/header';
import SectionHeader from '../../section-components/section-header/section-header';
import { Button, Form, FormControl, Col, Row, InputGroup, Alert } from 'react-bootstrap';
import { headerImage } from '../../../assets/images';
import SurveyErrorToast from '../../crossapp-utils/toast-error/survey-toast-error';
import ProgressSpinner from '../../crossapp-utils/progress-spinner/progress-spinner';
import QuestionLayout from '../../survey/question-layout/question-layout';

//SERVICES
import SupportService from '../../../services/Support-Service';
import SessionService from '../../../services/Session-Service';

//UTILS MODELS CONSTANTS
import Ticket from '../../../models/Ticket';
import { TicketTypes } from '../../../utils/constants/Ticket-Types';
import SurveyStrings from '../../../utils/constants/Strings';
import SessionStorage from '../../../utils/constants/SessionStorage';
import '../../../assets/custom-styles/custom-styles.css';
import UploadService from '../../../services/Upload-Service';
import { BASE_URL } from '../../../utils/constants/environment';

const SupportForm = () => {
    //HOOKS
    const params = useParams();
    const [searchParam] = useSearchParams();

    //LOCAL VARIABLES
    const [data, setData] = useState<Ticket>({
        userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID) || "",
        surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID) || "",
        senderMail: '',
        type: searchParam.get('type') === 'ticket' ? TicketTypes.TICKET : TicketTypes.REOPEN,
        message: '',
        attachment: ''
    })

    const [completed, setCompleted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(true);
    const [alert, setAlert] = useState<boolean>(false);

    const reader = new FileReader();

    const submitTicket = (e: any) => {

        if (completed) {
            setLoading(true);

            SupportService.sendTicket(data)
                .then(res => {
                    toast.success(SurveyStrings.SUPPORT_SENT, { autoClose: 3000 });
                    setData({
                        userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID) || "",
                        surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID) || "",
                        senderMail: '',
                        type: searchParam.get('type') === 'ticket' ? TicketTypes.TICKET : TicketTypes.REOPEN,
                        message: '',
                        attachment: ''
                    });
                })
                .catch(err => {
                    toast.error(
                        <SurveyErrorToast
                            link={false}
                            first_message={SurveyStrings.SUPPORT_ERROR}
                            second_message={SurveyStrings.SUPPORT_ERROR_LINE_TWO}
                        />
                    )
                })
                .finally(() => setLoading(false))
        }
    };

    //Upload handler
    const onFileUpload = (file: File[]) => {
        if (file[0]) {
            if (file[0].size <= 10485760) {
                setAlert(false)
                UploadService.uploadFile(file[0])
                    .then(res => {
                        setData({
                            ...data, 
                            attachment: BASE_URL + res.payload, 
                            fileName: file[0].name
                        });
                    })
                    .catch(err => {
                        toast.error(
                            <SurveyErrorToast
                                link={false}
                                first_message={SurveyStrings.SUPPORT_ERROR}
                                second_message={SurveyStrings.SUPPORT_ERROR_LINE_TWO}
                            />
                        );
                    })
                
            } else {
                setAlert(true)
                setData({...data, attachment: 'too big'})
            }
        }

    };

    //Change handler
    const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'senderMail') setValid(e.target.validity.valid)
    };

    //Form validation
    useEffect(() => {
        if (data.message && data.senderMail && data.message.length >= 30 && valid && !alert) setCompleted(true);
        else setCompleted(false);
    }, [data, valid, alert])

    //Url params
    useEffect(() => {
        params.userId && SessionService.setItem('userId', params.userId)
        params.surveyId && SessionService.setItem('surveyId', params.surveyId)
    }, [params.userId, params.surveyId])

    //JSX form element to render inside QuestionLayout component
    const form: JSX.Element = <>

        <p className='my-3 px-5'>
            {
                searchParam.get('type') === 'reopen'
                    ? SurveyStrings.REOPEN_TEXT
                    : SurveyStrings.SUPPORT_TEXT
            }
        </p>

        <div className='justify-content-center w-100 p-0 p-md-5 container'>

            <InputGroup hasValidation>
                <Form.Control
                    name='senderMail'
                    className='col my-3'
                    type='email'
                    value={data.senderMail}
                    isInvalid={!valid}
                    onChange={onChange}
                    placeholder={SurveyStrings.SUPPORT_MAIL}
                />
            </InputGroup>

            <Form.Control
                name='message'
                className='my-3'
                as='textarea'
                rows={5}
                value={data.message}
                placeholder={SurveyStrings.SUPPORT_BODY}
                onChange={onChange}
            />

            <Row className='d-flex align-items-center'>
                <Col xs={12} md={10}>
                    <FormControl
                        id='attachment'
                        name='attachment'
                        className='my-3'
                        type='file'
                        accept='*'
                        onChange={(e: any) => onFileUpload(e.target.files)}
                    />
                </Col>
                <Col xs={12} md={2}>
                    <Button
                        className='my-3'
                        disabled={data.attachment === ''}
                        onClick={() => {
                            $('#attachment').val('');
                            setData({ ...data, attachment: '' });
                            setAlert(false)
                        }}
                    >
                        {SurveyStrings.ATTACHMENTS_CANCEL}
                    </Button>

                </Col>
            </Row>
            {alert && <Row className='d-flex align-items-center'>
                <Alert variant='danger'>{SurveyStrings.ALERT_MAIL}</Alert>
            </Row>}


            <div className='mt-5 pe-1 d-flex justify-content-end'>

                <Button
                    disabled={!completed}
                    onClick={submitTicket}>
                    {SurveyStrings.SEND}
                </Button>


            </div>
        </div>


    </>

    return <>
        <div className='support__outerBox'>
            {loading && <ProgressSpinner message={SurveyStrings.SENDING} />}
            {!loading &&
                <>
                    <SurveyHeader
                        images={[headerImage]}
                    />

                    <SectionHeader
                        title='Assistenza'
                    />

                    <QuestionLayout
                        questionText={searchParam.get('type') === 'reopen' ? SurveyStrings.REOPEN : SurveyStrings.SUPPORT}
                        question={form}
                    />
                </>
            }

        </div>
    </>
}

export default SupportForm;