import SectionConfiguration from "../models/Section-Configuration";

export function RecapSectionParser (conf: SectionConfiguration[]): SectionConfiguration[] {
    const out : SectionConfiguration[] = [];
    conf.forEach(section => {
        out.push(section);
        if(section.sub_sections && section.sub_sections.length > 0){
            section.sub_sections.forEach(sub => out.push(sub))
        }
    })
    return out

}