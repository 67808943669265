import '../../../assets/custom-styles/custom-styles.css';

type SurveyHeaderProps = {
    images: string[];
};

const SurveyHeader = (props: SurveyHeaderProps) => {
    
    const {images} = props;

    return <>
        <div className='d-flex justify-content-center w-100 py-5'>
            <div className='width_80'>
            {images.map((image: string, index: number) => {
                return  <img 
                    className='img-fluid'
                    key={`${image}-${index}-key`} 
                    src={image} 
                    alt={image}
                />}
            )}
            </div>            
        </div>
    </>
};

export default SurveyHeader;