import SurveyStrings from "../../../utils/constants/Strings"
import SurveyHeader from "../../crossapp-utils/header/header"
import { headerImage } from '../../../assets/images';


const LinkError = () => {
    return <div className='vh-100'>

    <SurveyHeader images={[headerImage]} />

    <div className='d-flex justify-content-center w-100 my-5'>
        
        <div className='customHeaders m-2 width_80 p-3'>
            <h4 className='my-3'> {SurveyStrings.LOGIN_FORM_ERROR} </h4>
        </div>

    </div>

</div>
}

export default LinkError