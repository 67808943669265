import React, { useEffect, useState } from 'react'

//Libraries
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';

//Components
import SurveyHeader from '../../crossapp-utils/header/header';
import HomeBox from '../../pages/home-box/home-box';
import { Button, Col, Row } from 'react-bootstrap';
import ProgressSpinner from '../../crossapp-utils/progress-spinner/progress-spinner';
import SectionContainer from '../../section-components/section-container/section-container';
import SectionDownloader from '../../section-components/section-downloader/section-downloader';
//Models, assets & utils
import { MatrixAnswers, QuestionAnswer } from '../../../models/Answers-Configuration';
import SectionConfiguration, { SectionNavigation } from '../../../models/Section-Configuration';
import { headerImage } from '../../../assets/images';
import SurveyStrings from '../../../utils/constants/Strings';
import { RecapSectionParser } from '../../../utils/recap-section-parser';
import AppRoute from '../../../utils/constants/AppRoute';
import SessionStorage from '../../../utils/constants/SessionStorage';

//Services
import NavigateService from '../../../services/Navigate-Service';
import AnswersService from '../../../services/Answers-service';
import SessionService from '../../../services/Session-Service';
import DownloadModal from './download-modal';
import SurveyErrorToast from '../../crossapp-utils/toast-error/survey-toast-error';

const RecapContainer = () => {

    const navigate = useNavigate();

    const [recaps, setRecaps] = useState<SectionConfiguration[]>();
    const [answers, setAnswers] = useState<(QuestionAnswer | MatrixAnswers)[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [dropdown, setDropdown] = useState<SectionNavigation[]>([])
    const downloadMessage = SurveyStrings.DOWNLOAD_MESSAGE;

    //INIT CALL
    const initialGet = async () => {
        const navigationResponse = await NavigateService.getSection();
        const recapsResponse: SectionConfiguration[] = navigationResponse.payload.config.sections;
        setDropdown(navigationResponse.payload.dropdown);
        setRecaps(RecapSectionParser(recapsResponse));
        const answersResponse = await AnswersService.getAnswers();
        setAnswers(answersResponse.payload.answers);
        if (!answersResponse.payload.closed) {
            navigate(AppRoute.LINK_ERROR);
        }
    }

    const sessionUserId = SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID);
    const sessionSurveyId = SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID);

    useEffect(() => {
        if (loading && sessionUserId && sessionSurveyId) {
            //GET SECTION FROM BE
            initialGet()
                .then(res => res)
                .catch(err => toast.error(err.message))
                .finally(() => setLoading(false));
        } else if (!sessionUserId && !sessionSurveyId) {
            navigate(AppRoute.LINK_ERROR)
        }
    })

    //click support
    const supportClick = () => {
        navigate(`${AppRoute.SURVEY}${AppRoute.SUPPORT}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID) ?? '_'}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID) ?? '_'}?type=reopen`)
    }

    //EXPORT PDF
    const PDF = async () => {
        setDownloading(true);
        await new Promise(resolve => setTimeout(resolve, 2000))

        try {
            const output: ArrayBuffer[] = [];
            const doc = new jsPDF('p', 'px', 'a0');
            const div = document.getElementById('recap-page');
            if(div) {
                if(div.children) {
                    const elements = div.getElementsByClassName('btn-check');
                    Object.values(elements).forEach(e => {
                        if(e instanceof HTMLElement) {
                            e.style.display = 'none'
                        }
                    })
                }
                doc.html(div)
                    .then(async() => {
                        return output.push(doc.output('arraybuffer'))
                    })
                    .catch(e => console.log(e))
                    .finally(() => {
                        doc.save('Survey.pdf');
                        setDownloading(false);
                    })
            }
           
        } catch(e) {
            console.log('Pdf download error', e)
            setDownloading(false);
            toast.error(
                <SurveyErrorToast 
                    link={true}
                    first_message={SurveyStrings.DOWNLOAD_FAIL}
                    second_message={SurveyStrings.DOWNLOAD_FAIL_TWO}
                />
            )
        } 
    }


    return <>

        {
            loading &&
            <ProgressSpinner message={'Caricamento in corso'} />
        }
        <DownloadModal
            downloading={downloading}
            downloadMessage={downloadMessage}
        />

        {recaps && answers ? <>
                <SurveyHeader images={[headerImage]} />

                <HomeBox link={false} text={SurveyStrings.RECAP_TEXT} />

                <Row>
                    <Col sm={4}>
                        <SectionDownloader
                            recap={true} 
                            config={recaps}
                            answers={answers}
                            dropdown={dropdown}
                        />
                    </Col>
                    <Col sm={4}>
                        <Button onClick={supportClick} className='my-2'>{SurveyStrings.SUPPORT}</Button>
                    </Col>

                    <Col sm={4}>
                        <Button onClick={PDF} className='my-2'>{SurveyStrings.EXPORT_PDF}</Button>
                    </Col>

                </Row>
                <div id="recap-page">
                    {recaps.map((entry, index) =>
                        <div 
                            id={entry.section.id}
                            key={`${entry}-${index}`}
                        >
                            <SectionContainer
                                answers={answers}
                                isReadOnly={true}
                                configuration={entry}
                                operations={{ id: '', version: 0, operations: {} }}
                                updateSessionAnswers={() => {}}
                                setError={() => {}}
                                saveProjectAnswer={() => { }}
                            />
                        </div>
                    )}
                </div>
        </> : <></>}
    </>
};

export default RecapContainer;