import { ToastContainer, Slide } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const SurveyToast = () => {

    return <>
        <ToastContainer
            position="top-right"
            autoClose={false}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
            theme="colored"
            transition={Slide}
            icon={false}
        />
    </>
}

export default SurveyToast;