import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import QuestionConfiguration from "../../../models/Question-Configuration";

type DoubleRadioProps = {
    config: QuestionConfiguration;
    be_answer: { [key: string]: { [key: string]: string } };
    isReadOnly?: boolean;
    onUserAnswer: (config: QuestionConfiguration, value: { [key: string]: { [key: string]: string} }) => void
}

const DoubleRadio = (props: DoubleRadioProps) => {

    const { config, be_answer, isReadOnly, onUserAnswer } = props;

    const [answer, setAnswer] = useState<{ [key: string]: { [key: string]: string } }>(be_answer);

    const handleChange = (col: string, line: string, ans: string) => {

        const update = { ...answer };

        if (answer[col][line] && answer[col][line] === ans)  update[col][line] = '';
        else update[col][line] = ans;

        setAnswer(update);
        onUserAnswer(config, update);
    };


    return <>

        <div className='d-none d-md-flex row'>
            <div className='col-sm-2'>

            </div>

            <div className='col'>
                <div className='row'>
                    {config.radio_columns?.map(col =>
                        <div
                            key={`${col}-double-radio-heading`}
                            className='col mb-3'
                        >
                            <h5>{col}</h5>
                        </div>
                    )}
                </div>
            </div>
        </div>

        <div className='d-none d-md-flex row'>
            <div className='col-sm-2'>

            </div>

            <div className='col'>
                <div className='row'>
                    {config.radio_columns?.map(col =>
                        config.answers?.map((entry, index) =>
                            <div
                                key={`${entry}-${index}-${col}-double-radio-label`}
                                className='col'
                            >
                                {entry}
                            </div>
                        ))}
                </div>
            </div>
        </div>


        {config.lines!.map((line: string, li: number) => {
            return (
                <div
                    className='row py-3 radioMatrix'
                    key={`${li}-${line}-radio-div-key`}
                >
                    <div className='col-sm-2 col-md-2 mt-2 mt-md-0 matrix__labels'>
                        <span>{line} </span>
                    </div>

                    <div className='col'>
                        <div className='row matrix__boxes'>

                            {config.radio_columns?.map((col: string) =>
                                    config.answers?.map((ans, ind) =>
                                        <div
                                            className={
                                                ind + 1 === config.answers?.length ?
                                                    'col-sm row-md d-flex justify-content-sm-start justify-content-md-center mt-3 mt-md-0 border-end border-3'
                                                    : 'col-sm row-md d-flex justify-content-sm-start justify-content-md-center mt-3 mt-md-0'
                                            }
                                            key={`${ans}-${col}-${line}-${li}-${ind}-radio-key`}
                                        >
                                            <Form.Check
                                                disabled={isReadOnly}
                                                readOnly={isReadOnly}
                                                name={ans}
                                                type='checkbox'
                                                onChange={(e: any) => handleChange(col, line, ans)}
                                                checked={(answer[col][line] && answer[col][line] === ans ) || false}
                                            />

                                            <p className='d-md-none ms-2 text-align-center'>{ans}</p>
                                        </div>
                                    ))}
                        </div>
                    </div>
                </div>
            )})}
    </>
}
export default DoubleRadio;
