export const titlestyle = {
    fill: {
        patternType: 'solid',
        fgColor: { rgb: '025BB3' }
    },
    font: {
        name: "Calibri",
        sz: 14,
        bold: true,
        color: { rgb: 'FFFFFF' }
    },
    alignment: {
        wrapText: true,
        vertical: 'center'
    }
};

export const labelstyle = {
    fill: {
        patternType: 'solid',
        fgColor: { rgb: 'e2e2e2' }
    },
    font: {
        name: "Calibri",
        sz: 12,
        bold: true,
        color: { rgb: '3f3f3f' }
    },
    alignment: {
        wrapText: true,
        vertical: 'top',
        horizontal: 'center'
    }
}

export const cellstyle = {
    fill: {
        patternType: 'solid',
        fgColor: { rgb: 'F6F9FC' }
    },
    font: {
        name: "Calibri",
        sz: 12,
        bold: false,
        color: { rgb: '000000' }
    },
    alignment: {
        wrapText: true,
        vertical: 'center',
        horizontal: 'left'
    }
};

export const defaultstyle = {
    alignment: {
        wrapText: true,
        vertical: 'center',
        horizontal: 'center'
    },
    font: {
        name: "Calibri",
        sz: 12,
        bold: true,
        color: { rgb: '000000' }
    }
};


export enum CellStyleTypes {
    TITLE_BOX = 'title_box',
    LABEL_CELL = 'label_cell',
    ANSWER_CELL = 'answer_cell',
    COLUMN_LABEL = 'column_label',
    MATRIX_CELL = 'matrix_cell'
};

export enum Selectors {
    SHEETS = 'Sheets',
    MERGES = '!merges',
    REF = '!ref',
    STYLE = 's',
    COLS = '!cols',
    ROWS = '!rows'
}

//sort function for indexing cells 
export function sortFunction(firstInput: any, secondInput: any): any {
    const regex = /^([a-z]*)(\d*)/i;

    const firstSub = firstInput.match(regex);
    const secondSub = secondInput.match(regex);

    if (firstSub[1] < secondSub[1]) return -1;
    if (firstSub[1] > secondSub[1]) return 1;

    const index = parseInt(firstSub[2]) - parseInt(secondSub[2]);
    if (index === 0) return sortFunction(firstInput.substr(firstSub[0].length), secondInput.substr(secondSub[0].length));

    return index;
};