import { MatrixAnswers, QuestionAnswer } from "../models/Answers-Configuration";
import QuestionConfiguration, { Trigger, MatrixConfiguration, MatrixColumnConfig, MatrixCell, OuterEnabler, MatrixInputs, MatrixCallbackValues } from "../models/Question-Configuration";
import OperationsModel from "../models/Operations";


/*
    * DEVELOPMENT ONLY FUNCTION: Removes all the triggers from a Matrix config array; 
*/
export function removeTrigger(input: MatrixConfiguration[]) {

    input.forEach((question, questionIndex) => {

        question.columns.forEach((column, columnIndex) => {

            column.column.forEach((cell, cellIndex) => {

                if (cell.trigger) delete cell.trigger;
            })
        })

    });

    console.log('OUTPUT', JSON.stringify(input));
};

/*
    * DEVELOPMENT ONLY
    * Takes a Matrix configuration array and parses each cell's operation object
    * Inside each of these objects there should be an array of inputs which the function uses
    * to generate in the corresponding cell a trigger object.
    * Before using it make sure that each cell has an empty trigger array since the function doesn't
    * check for existence
*/
export function addTrigger(input: MatrixConfiguration[]) {

    const update = input;

    update.forEach(question => {

        question.columns.forEach(column => {

            column.column.forEach(cell => {

                if (cell.operation && cell.operation.inputs) {

                    cell.operation.inputs.forEach(input => {

                        const trigger = {
                            question_id: question.id,
                            column_id: column.column_id,
                            cell_id: cell.cell_id
                        };

                        const destination: MatrixConfiguration | undefined = update.find(q => q.id === input.question_id);
                        if (destination) {
                            const destinationCol: MatrixColumnConfig | undefined = destination.columns.find(col => col.column_id === input.column_id);
                            if (destinationCol) {
                                const destinationCell: MatrixCell | undefined = destinationCol.column.find(cell => cell.cell_id === input.cell_id);
                                if (destinationCell) {

                                    if (destinationCell.trigger) {

                                        destinationCell.trigger.push(trigger)
                                    }

                                }
                            }
                        }
                    })
                }

            })
        })
    })
    console.log('OUTPUT ', JSON.stringify(update))
};
//Formats an input string (containing digits 0-9) into a currency string with decimals dot dividers
export function CurrencyFormat(input: string) {
    return input.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
};
//Turns a currency formatted string into a plain string, to be performed before each operation
export function CurrencyDeformat(input: string) {
    return input.replaceAll('.', '')
};

export interface OuterEnablerParams {
    enabler: OuterEnabler[],
    value: any,
    answers: (MatrixAnswers | QuestionAnswer)[]
}

export interface OuterEnablerReturn {
    answers: (MatrixAnswers | QuestionAnswer)[],
    diffs: { index: number, value: MatrixAnswers }[],
}

/*
    * OUTER ENABLER - CC01C && CC01D special matrixes
    * This function enables/disables entire rows in the target answers based on whether a 
    * corresponding column in the CC01B question has been answered at least once
    * 
    * Invoked inside the section parser, is followed by the retrigger operations function
    * to update the totals row
*/
export function matrixOuterEnabler(config: OuterEnablerParams): OuterEnablerReturn {

    const { enabler, value, answers } = config;
    const targetArray: { index: number, value: MatrixAnswers }[] = [];
    //update answer
    enabler?.forEach((enabler: OuterEnabler) => {
        const target = answers?.find(entry => "matrix_id" in entry && entry.matrix_id === enabler.question_id) as MatrixAnswers;

        target.columns.forEach((col, colIndex) => {
            if (colIndex > 3) {

                
                //the first 4 columns are unaffected
                //Currently uses row index for identifying the row, should be using the position key instead
                if((value[enabler.key] && value[enabler.key].length > 0))  {
                    col.column[enabler.row_index].type = 'input';
                } else {   
                    col.column[enabler.row_index].type = 'slave';
                    col.column[enabler.row_index].value = '';
                };
            } else {
                col.column[enabler.row_index].type = 'slave';
            }
        })

    });
    //store changed ids
    const reduced = enabler?.reduce(function (a: string[], b: OuterEnabler) {
        if (a.indexOf(b.question_id) === -1) {
            a.push(b.question_id)
        }
        return a
    }, []);
    //return changed answers
    reduced?.forEach(red => targetArray.push({
        index: answers.findIndex(entry => "matrix_id" in entry && entry.matrix_id === red) || 0,
        value: answers.find(entry => 'matrix_id' in entry && entry.matrix_id === red) as MatrixAnswers
    }))
    return { answers: answers, diffs: targetArray }

};

/*
    * OPERATION PARSER
    * Uses an inputs string array, a formula identifier (contained in the operation object inside a cell) and
    * the operations passed by the BE from the config GET
    * 
    * Uses the function constructor to turn a string operation into an actual JS function
    * Must always disable eslint before invoking the function constructor since its won't compile otherwise
    * 
    * Using the Function constructor is generally discouraged and poses a security risk, thus eslint stops it
*/
export function matrixOperationParser(inputs: string[], formula: string, operations: OperationsModel): string {
    const parsedInputs = inputs.map(entry => entry.replaceAll('.', ''));

    // eslint-disable-next-line no-new-func
    return Function('inputs', 'return ' + operations.operations[formula].formula)(parsedInputs);
};

/* MATRIX ACTION TRIGGERING */
export interface TriggerActionConfig {
    source: MatrixCell;
    sectionQuestions: (MatrixConfiguration | QuestionConfiguration)[];
    answersStorage: (MatrixAnswers | QuestionAnswer)[];
    operations: OperationsModel
}

export interface TriggerReturn {
    config: MatrixConfiguration,
    value: any,
    matrix: MatrixCallbackValues
}
/*
    * TRIGGER ACTION
    * Invoked in the sectionParser after storing an answer value if the cell containing it has a trigger object inside
    * 
    * The function iterates all the triggers within the specified cell and returns an array of their answers' values,
    * this array is used as input in the function constructor and the result is then passed again to the 
    * matrixStoreAnswers method in a TriggerReturn object array
*/
export function matrixTriggerAction(config: TriggerActionConfig): TriggerReturn[] {

    const { source, sectionQuestions, answersStorage, operations } = config;

    const toStore: TriggerReturn[] = [];

    source.trigger?.forEach(sourceTrigger => {

        //DESTINATION OBJS && INDEXES
        const destination = triggerToDestination(sourceTrigger, sectionQuestions);

        if (destination.destinationCell.operation && destination.destinationCell.operation.inputs) {

            const output: any[] = inputsToValues(destination.destinationCell.operation.inputs, answersStorage);

            // eslint-disable-next-line no-new-func
            let operationResult = Math.round(Function('return ' + matrixOperationParser(output, destination.destinationCell.operation.formula, operations))());

            if (isNaN(operationResult)) operationResult = 0;
            const result = {
                config: destination.destinationQuestion,
                value: operationResult.toString(),
                matrix: {
                    colId: destination.destinationColumn.column_id,
                    cellId: destination.destinationCell.cell_id
                }
            };
            toStore.push(result)
        }
    })
    return toStore
};


/*
    * CONSTRAINT TRIGGERING
    * The method verifie if a cell has any constraint and, should it have one, elaborates it.
    * Constraints are functions stored in the BE operations collection that get parsed using the 
    * function constructor and return a boolean should the result respect or not the given constraint
    * 
    * The result is sent to the setErrorStatus function which enables/disables navigation and saving
*/
export interface ConstraintValues {
    cell: MatrixCell,
    config: MatrixConfiguration,
    value: any,
    answersStorage: (MatrixAnswers | QuestionAnswer)[],
    operations: OperationsModel,
    setQuestionError: Function
}

export function matrixTriggerConstraint(configuration: ConstraintValues): void {
    const { cell, config, value, answersStorage, operations, setQuestionError } = configuration;

    if (cell.constraint && cell.constraint.inputs) {
        const output = inputsToValues(cell.constraint.inputs, answersStorage);

        // eslint-disable-next-line no-new-func
        let constraintRespected: boolean = Function('return ' + matrixOperationParser(output, cell.constraint?.formula, operations))();
        setQuestionError(config, constraintRespected, cell.constraint?.formula, value);

    }
};

//Utility function to turn an array of operation inputs into an array of their corresponding question values
export function inputsToValues(inputs: MatrixInputs[], answers: (MatrixAnswers | QuestionAnswer)[], tester?: string): any[] {

    const values: any[] = [];
    inputs.forEach((input) => {
        const a = { ...answers.find(answer => "matrix_id" in answer && answer.matrix_id === input.question_id) } as MatrixAnswers;

        const c = a.columns.find(col => col.column_id === input.column_id);

        const val = c!.column.find(cell => cell.cell_id === input.cell_id)?.value;

        if (val && !isNaN(parseInt(val))) values.push((val));
        else values.push('0');
    });
    return values
};
//Utility function used to match a trigger object with its corresponding config object and obtain all its element's ids
export function triggerToDestination(trigger: Trigger, questions: (MatrixConfiguration | QuestionConfiguration)[]) {
    const question = { ...questions.find(entry => entry.id === trigger.question_id) } as MatrixConfiguration;
    const column = { ...question.columns.find(entry => entry.column_id === trigger.column_id) } as MatrixColumnConfig;
    const cell = { ...column.column.find(entry => entry.cell_id === trigger.cell_id) } as MatrixCell;
    const columnIndex = question.columns.findIndex(entry => entry.column_id === trigger.column_id);
    const cellIndex = column.column.findIndex(entry => entry.cell_id === trigger.cell_id);

    const output = {
        destinationQuestion: question,
        destinationColumn: column,
        destinationCell: cell,
        columnIndex: columnIndex,
        cellIndex: cellIndex
    };

    return output
}
