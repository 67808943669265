import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';

//COMPONENTS && ASSETS
import SectionContainer from "../../section-components/section-container/section-container";
import SurveyFooter from "../../crossapp-utils/footer/footer";
import SurveyHeader from '../../crossapp-utils/header/header';
import DropdownNav from '../../crossapp-utils/dropdown-nav/dropdown-nav';
import { ProgressBar } from 'react-bootstrap';
import { headerImage } from '../../../assets/images';
import ProgressSpinner from '../../crossapp-utils/progress-spinner/progress-spinner';
import { toast } from 'react-toastify';
import SurveyErrorToast from '../../crossapp-utils/toast-error/survey-toast-error';

//UTILS
import Navigation from '../../../utils/constants/Navigation';
import AppRoute from '../../../utils/constants/AppRoute';

//MODELS
import { MatrixAnswers, QuestionAnswer } from '../../../models/Answers-Configuration';
import OperationsModel from '../../../models/Operations';
import SectionConfiguration, { SectionNavigation } from '../../../models/Section-Configuration';

//SERVICES && CONSTANTS
import AnswersService from '../../../services/Answers-service';
import NavigateService from '../../../services/Navigate-Service';
import SessionService from '../../../services/Session-Service';
import SessionStorage from '../../../utils/constants/SessionStorage';
import SurveyStrings from '../../../utils/constants/Strings';

type SurveyProps = {
    startPage: number;
};

const SurveyContainer = (props: SurveyProps) => {

    const { startPage } = props;

    //HOOKS
    const navigator = useNavigate();
    const params = useParams();
    const { pathname } = useLocation();

    //CONFIGURATION STATUS
    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [, setAutosave] = useState<number>();

    //FETCHED VALUES
    const [surveySections, setSurveySections] = useState<SectionConfiguration[]>();
    const [surveyOperations, setSurveyOperations] = useState<OperationsModel>({ id: '', operations: {}, version: 0 });
    const [surveyAnswers, setSurveyAnswers] = useState<(QuestionAnswer | MatrixAnswers)[]>();
    const [surveyNavigation, setSurveyNavigation] = useState<SectionNavigation[]>();
    const [surveyProgress, setSurveyProgress] = useState<number>(0);

    //CALLBACK VALUES
    const [sessionAnswers, setSessionAnswers] = useState<Map<string, QuestionAnswer | MatrixAnswers>>(new Map());
    const [errorStatus, setErrorStatus] = useState<boolean>(false);

    //NAVIGATION
    const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(startPage);
    const [currentSection, setCurrentSection] = useState<SectionConfiguration>();
    const [currentSubsection, setCurrentSubsection] = useState<SectionConfiguration>();
    const [availableSubsections, setAvailableSubsections] = useState<SectionConfiguration[]>();

    type NavConfig = {
        current?: SectionConfiguration,
        currentIndex: number,
        currentSub?: SectionConfiguration,
        availableSubs?: SectionConfiguration[],
        answers?: (QuestionAnswer | MatrixAnswers)[],
        sections: SectionConfiguration[],
        newIndex: number,
    }

    //BUTTONS NAVIGATION - RETURN NEW PAGE INDEX
    const parseNavigationSwitch = (e: Navigation, index: number): number => {
        switch (e) {
            case (Navigation.FORWARD): return index + 1
            case (Navigation.BACK): return index - 1
            default: return index
        }
    };
    //ELABORATE NAVIGATION AFTER SURVEY UPDATE
    const elaborateNav = async (nav: NavConfig, menu: boolean) => {
        if (menu && surveySections) {
            //MENU NAVIGATION
            const newSection = surveySections[nav.newIndex] as SectionConfiguration;

            setAvailableSubsections(undefined);
            setCurrentSubsection(undefined);
            setCurrentSection(newSection);
            setCurrentSectionIndex(nav.newIndex);

            navigator(`${AppRoute.SURVEY}/${newSection.section.id}`);
        };

        if (!menu && nav.current?.sub_sections && nav.current.sub_sections.length > 0 && nav.currentSub === undefined && nav.newIndex > nav.currentIndex) {

            //ENTER SUBSECTIONS
            setCurrentSection(nav.current);
            setSurveySections(nav.sections);
            setAvailableSubsections(nav.current.sub_sections);
            setCurrentSubsection(nav.current.sub_sections[0]);

        } else if (!menu && nav.current?.sub_sections && nav.currentSub !== undefined && nav.newIndex < nav.currentIndex) {

            //LEAVE SUBSECTIONS
            setCurrentSection(nav.current);
            setSurveySections(nav.sections);
            setAvailableSubsections(undefined);
            setCurrentSubsection(undefined);

        } else if (!menu && surveySections) {

            //SECTION HOPPING
            const newSection = surveySections[nav.newIndex] as SectionConfiguration;

            setCurrentSubsection(undefined);
            setAvailableSubsections(undefined);
            setCurrentSectionIndex(nav.newIndex);
            setCurrentSection(newSection);

            if (newSection && nav.newIndex >= 0 && nav.newIndex <= surveySections.length - 1) navigator(`${AppRoute.SURVEY}/${newSection.section.id}`);
            else if (nav.newIndex < 0) navigator(`${AppRoute.LOGIN}`);
            else if (nav.newIndex >= surveySections.length - 1) navigator(`${AppRoute.SUBMIT}`);

        }

        await sleeper()
    }

    //BUTTONS NAVIGATION
    const navigate = async (e: Navigation, menu?: boolean, menuIndex?: string) => {

        setSaving(true);
        const newIndex = (menu && menuIndex) ? parseInt(menuIndex) : parseNavigationSwitch(e, currentSectionIndex)

        if (surveySections) {
            if (sessionAnswers.size > 0 && !errorStatus) {
                //SAVES ANSWERS AND NAVIGATE
                const updatedAnswers: (MatrixAnswers | QuestionAnswer)[] = Array.from(sessionAnswers.values());
                AnswersService.updateAnswers(updatedAnswers)
                    .then(res => {
                        const test = (res.payload.config && Object.keys(res.payload.config).length > 0)
                        const newCurrentSection = test ? res.payload.config.sections?.find(entry => entry.section.id === currentSection?.section.id) as SectionConfiguration : currentSection;
                        const newCurrentSectionIndex = test ? res.payload.config.sections?.findIndex(entry => entry.section.id === currentSection?.section.id) : currentSectionIndex;

                        const navParams = {
                            current: newCurrentSection,
                            currentIndex: newCurrentSectionIndex,
                            currentSub: currentSubsection || undefined,
                            availableSubs: newCurrentSection?.sub_sections || undefined,
                            answers: res.payload.answers || surveyAnswers,
                            sections: res.payload.config.sections || surveySections,
                            newIndex: newIndex
                        };
                        setSurveySections((res.payload.config && res.payload.config.sections && Object.keys(res.payload.config).length > 0) ? res.payload.config.sections : surveySections);
                        setSurveyAnswers(res.payload.answers && Object.keys(res.payload.answers).length > 0 ? res.payload.answers : surveyAnswers);
                        setSurveyProgress(res.payload.progress.percentage)
                        SessionService.setItem(SessionStorage.SESSION_STORAGE_PROGRESS, res.payload.progress.percentage.toString());

                        setSessionAnswers(new Map());

                        elaborateNav(navParams, !!menuIndex);
                        toast.success(SurveyStrings.SAVE_SUCCESS, { autoClose: 3000 })
                    })
                    .catch(err => {
                        setSaving(false);
                        console.log('Navigate error: ', err);
                        toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.SAVE_ERROR} second_message={SurveyStrings.SAVE_ERROR_LINE_TWO} />)
                    })
            } else {
                //NON SAVING NAVIGATION
                const navParams = {
                    current: currentSection,
                    currentIndex: currentSectionIndex,
                    currentSub: currentSubsection,
                    availableSubs: availableSubsections,
                    answers: surveyAnswers,
                    sections: surveySections,
                    newIndex: newIndex
                }

                elaborateNav(navParams, !!menuIndex);
            }
        }

    };

    //DROPDOWN NAVIGATION
    const menuNavigate = (section: number) => {
        navigate(Navigation.INDEX, true, section.toString());
    };

    //SCROLL TO TOP AT EVERY RE-RENDER
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, [pathname, currentSubsection]);


    //INITIAL GET
    const initialGet = async () => {

        const navigationResponse = await NavigateService.getSection();
        const answersResponse = await AnswersService.getAnswers();
        if (answersResponse.payload.closed) {
            navigator(AppRoute.RECAP)
        } else {

            const position = navigationResponse.payload.dropdown.find(entry => entry.name === params.sectionId)?.name || answersResponse.payload.progress.position || navigationResponse.payload.config.sections[0].section.id;
            const responseSections = navigationResponse.payload.config.sections as SectionConfiguration[];
            const startSection = responseSections.find(entry => entry.section.id === position);
            const startSectionIndex = responseSections.findIndex(entry => entry.section.id === position);

            setCurrentSection(startSection);
            setCurrentSectionIndex(startSectionIndex);
            setSurveyNavigation(navigationResponse.payload.dropdown);
            setSurveySections(navigationResponse.payload.config.sections);
            setSurveyOperations(navigationResponse.payload.operations);
            setSurveyAnswers([...answersResponse.payload.answers]);
            if (answersResponse.payload.progress.percentage) {
                setSurveyProgress(answersResponse.payload.progress.percentage);
                SessionService.setItem(SessionStorage.SESSION_STORAGE_PROGRESS, answersResponse.payload.progress.percentage.toString());
            };
            if (startSection && startSection.sub_sections) {
                setAvailableSubsections(startSection.sub_sections)
            };

            navigator(`${AppRoute.SURVEY}/${position}`)
        }
    };

    //CALLS INITIAL GET
    const sessionUserId = SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID);
    const sessionSurveyId = SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID);

    useEffect(() => {

        if (loading && sessionUserId && sessionSurveyId) {
            initialGet()
                .then(res => res)
                .catch(err => {
                    console.log('Init get effect error: ', err);
                    toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.INIT_GET_ERROR} second_message={SurveyStrings.INIT_GET_ERROR_LINE_TWO} />)
                })
                .finally(() => {
                    toast.success(SurveyStrings.INIT_GET_SUCCESS, { autoClose: 3000 })
                    setLoading(false)
                });
        } else if (!sessionUserId && !sessionSurveyId) {
            navigator(AppRoute.LINK_ERROR)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, sessionUserId, sessionSurveyId]);


    //SAVE PROGRESS (WON'T TRIGGER IF THERE AREN'T NEW ANSWERS)
    const saveAnswers = async () => {

        const updatedAnswers: (MatrixAnswers | QuestionAnswer)[] = Array.from(sessionAnswers.values());
        return AnswersService.updateAnswers(updatedAnswers)
            .then(res => {
                const test = (res.payload.config && Object.keys(res.payload.config).length > 0)
                const newCurrentSection = test ? res.payload.config?.sections.find(entry => entry.section.id === currentSection?.section.id) as SectionConfiguration : currentSection;
                const newCurrentSectionIndex = test ? res.payload.config?.sections.findIndex(entry => entry.section.id === currentSection?.section.id) : currentSectionIndex;

                setCurrentSection(newCurrentSection);
                setCurrentSectionIndex(newCurrentSectionIndex);

                setCurrentSubsection(currentSubsection || undefined);
                setAvailableSubsections(newCurrentSection?.sub_sections || undefined);

                setSurveyAnswers(res.payload.answers);
                setSurveyProgress(res.payload.progress.percentage)
                SessionService.setItem(SessionStorage.SESSION_STORAGE_PROGRESS, res.payload.progress.percentage.toString());
                setSessionAnswers(new Map());
            })
            .catch(err => {
                console.log('Save answers error : ', err)
                toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.SAVE_ERROR} second_message={SurveyStrings.SAVE_ERROR_LINE_TWO} />)
            })
            .finally(async () => {
                await sleeper()
                toast.success(SurveyStrings.SAVE_SUCCESS, { autoClose: 3000 })
            })

    }

    //SAVE PROJECT AND UPDATE SUBSECTION
    const saveProject = async (projectId: string) => {
        setSaving(true);
        if (sessionAnswers.size > 0 && !errorStatus) {

            const updatedAnswers: (MatrixAnswers | QuestionAnswer)[] = Array.from(sessionAnswers.values());
            AnswersService.updateAnswers(updatedAnswers)
                .then(res => {
                    setSurveyAnswers(res.payload.answers);
                    setSurveyProgress(res.payload.progress.percentage);
                    SessionService.setItem(SessionStorage.SESSION_STORAGE_PROGRESS, res.payload.progress.percentage.toString());
                    setSessionAnswers(new Map());
                    const newProject = availableSubsections?.find(entry => entry.section.id === projectId) as SectionConfiguration;
                    setCurrentSubsection(newProject);
                })
                .catch(err => {
                    console.log('Save project error: ', err)
                    toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.SAVE_ERROR} second_message={SurveyStrings.SAVE_ERROR_LINE_TWO} />)
                })
                .finally(() => {
                    setSaving(false)
                    toast.success(SurveyStrings.SAVE_SUCCESS, { autoClose: 3000 })
                });

        } else {

            const newProject = availableSubsections?.find(entry => entry.section.id === projectId) as SectionConfiguration;
            setCurrentSubsection(newProject);
            await sleeper();

        }

    }

    //AUTOSAVE EVERY 5 MINUTES
    useEffect(() => {
        const interval = setInterval(() => {
            if (sessionAnswers.size > 0 && !errorStatus) {
                setSaving(true);
                saveAnswers();
            };
            setAutosave(Number(interval));
        }, 300000);
        return () => clearInterval(interval);
    })

    //STORES SESSION ANSWERS PASSED THROUGH CALLBACK
    const updateSession = (answers: Map<string, MatrixAnswers | QuestionAnswer>) => {
        setSessionAnswers(new Map([...answers]));
    };
    //BLOCK NAVIGATION IF THERE'S AN ERROR
    const setError = (constraintsRespected: boolean) => {
        setErrorStatus(!constraintsRespected);
    };

    //UTILS TO TRIGGER SAVE SPINNER
    function sleep(ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms))
    };
    const sleeper = async () => {
        await sleep(2000);
        setSaving(false);
    };


    return <>
        {
            (loading || saving) &&
            <ProgressSpinner message={'Caricamento in corso'} />
        }

        {
            !saving && (surveySections && surveyNavigation) &&
            <>
                <ProgressBar className='rounded-0 fixed-top' now={surveyProgress}></ProgressBar>

                <SurveyHeader images={[headerImage]} />

                <DropdownNav
                    preventNav={errorStatus}
                    sections={surveyNavigation}
                    currentPage={surveyNavigation[currentSectionIndex].name}
                    buttonClick={menuNavigate}
                />
                {surveySections && surveyAnswers && currentSection &&

                    <SectionContainer
                        preventNavigation={errorStatus}
                        operations={surveyOperations}
                        answers={surveyAnswers}
                        configuration={currentSubsection ? currentSubsection : currentSection}
                        updateSessionAnswers={(answers: Map<string, MatrixAnswers | QuestionAnswer>) => updateSession(answers)}
                        subSections={availableSubsections}
                        setError={setError}
                        saveProjectAnswer={saveProject}
                    />

                }

                <SurveyFooter
                    preventNavigation={errorStatus}
                    btn_forward={navigate}
                    btn_back={navigate}
                    config={surveySections}
                    answers={surveyAnswers}
                    dropdown={surveyNavigation}
                    currentSection={currentSection}
                />

            </>
        }
    </>
};

export default SurveyContainer;

