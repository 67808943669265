import { useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import QuestionConfiguration from "../../../models/Question-Configuration";
import { CurrencyFormat } from "../../../utils/matrixutils";

type InputNumberProps = {
    config: QuestionConfiguration;
    isReadOnly?: boolean;
    be_answer: string[]
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
}

const InputNumber = (prop: InputNumberProps) => {

    const { config, be_answer, isReadOnly, onUserAnswer } = prop;

    const formatValue = (value: any) => {
        if (isNaN(value) && value < 10) return " ";
        else return value;
    };

    const handleChange = (value: string, index: number) => {
        const update = be_answer;
        update[index] = value;
        onUserAnswer(config, update);
    }

    useEffect(() => {
        console.log(be_answer)
    },[be_answer])

    return <>
        {config.lines?.map((line, index) => {
            return <InputGroup key={`${line}${index}`}>
                <Row className='w-100 mb-2'>
                    <Col sm={4}>
                        <Form.Text className='text-dark float-start'>{line}</Form.Text>
                    </Col>
                    <Col sm={8}>
                        <InputGroup >
                            <Form.Control
                                type='text'
                                value={be_answer.length ? CurrencyFormat(formatValue(be_answer[index])) : ''}
                                onChange={(e:any) => handleChange(e.target.value, index)}
                                disabled={isReadOnly} />
                        </InputGroup>
                    </Col>
                </Row>
            </InputGroup>
        })}

    </>
}

export default InputNumber