import { useEffect, useState } from "react";

import { useNavigate } from "react-router";

//Utils & constants
import { headerImage } from '../../../assets/images';
import AppRoute from "../../../utils/constants/AppRoute";
import SessionStorage from "../../../utils/constants/SessionStorage";
import SurveyStrings from "../../../utils/constants/Strings";

//Components
import { ProgressBar } from "react-bootstrap";

import SurveyHeader from "../../crossapp-utils/header/header";
import SurveyFooter from "../../crossapp-utils/footer/footer";
import SectionHeader from "../../section-components/section-header/section-header";
//Services
import AnswersService from "../../../services/Answers-service";
import SessionService from "../../../services/Session-Service";
import { toast } from "react-toastify";
import ProgressSpinner from "../../crossapp-utils/progress-spinner/progress-spinner";
import SurveyErrorToast from "../../crossapp-utils/toast-error/survey-toast-error";
import SectionProgress from "../section-progress/section-progress";
import { SectionProgressModel } from "../../../models/Section-Progress";
import NavigateService from "../../../services/Navigate-Service";
import { SectionNavigation } from "../../../models/Section-Configuration";
import DropdownNav from "../../crossapp-utils/dropdown-nav/dropdown-nav";


const SectionSubmit = () => {

    //HOOK
    const navigate = useNavigate();

    //STATE
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState<number>();
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [closed, setClosed] = useState<boolean>();
    const [surveyNavigation, setSurveyNavigation] = useState<SectionNavigation[]>();
    const [lastSection, setLastSection] = useState<string>();
    const [sectionProgress, setSectionProgress] = useState<SectionProgressModel[]>();


    const submitSurvey = () => {
        if (canSubmit) {
            AnswersService.submitSurvey()
                .then(res => {
                    console.log('submitRes', res);
                    //redirect to recap here
                    navigate(AppRoute.RECAP);
                })
                .catch(err => {
                    toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.SUBMIT_ERROR} second_message={SurveyStrings.SUPPORT_ERROR_LINE_TWO} />)
                    //show error here
                })
        } else {
            toast.error("Compilare tutte le domande obbligatorie")
        }
    };

     //DROPDOWN NAVIGATION
     const menuNavigate = (section: number, name: string) => {
         navigate(`${AppRoute.SURVEY}/${name}`)
    };

    useEffect(() => {
        AnswersService.getAnswers()
            .then(res => setClosed(res.payload.closed))
            .catch(err => toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.INIT_GET_ERROR} second_message={SurveyStrings.INIT_GET_ERROR_LINE_TWO} />))
            .finally(() => setLoading(false))

        //CHECK IF YOU CAN SUBMIT
        AnswersService.finalCheck()
            .then(res => {
                setCanSubmit(res.payload.canSubmit);
                setSectionProgress(res.payload.sectionProgresses);
                console.log('percentage', res.payload.percentage, 'submit', res.payload)
            })
            .catch(err => {
                console.log('Section submit init get error', err)
                toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.FINAL_CHECK_ERROR} second_message={SurveyStrings.SUPPORT_ERROR_LINE_TWO} />)
            })

        NavigateService.getDropdown()
            .then(res => {
                setSurveyNavigation(res.payload.dropdown)
                setLastSection(res.payload.dropdown[res.payload.dropdown.length - 1].name)
            })
            .catch(err => {
                console.log('Error get dropdown', err)
                toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.DROPDOWN_ERROR} second_message={SurveyStrings.SUPPORT_ERROR_LINE_TWO} />)
            })
    }, [])

    //RETRIEVE PROGRESS FROM SESSION SERVICE @ INIT
    useEffect(() => {
        const sessionUserId = SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID);
        const sessionSurveyId = SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID);

        if (closed) {
            navigate(AppRoute.RECAP)
        } else {
            if (sessionUserId && sessionSurveyId) {
                const sessionProgress = SessionService.getItem(SessionStorage.SESSION_STORAGE_PROGRESS);
                if (sessionProgress && !isNaN(parseInt(sessionProgress))) setProgress(parseInt(sessionProgress));
            } else if (!sessionUserId && !sessionSurveyId) {
                navigate(AppRoute.LINK_ERROR)
            }
        }
    }, [navigate, closed]);


    return <>

        {
            loading ? <ProgressSpinner message={'Caricamento in corso'} />
                : <div>

                    <ProgressBar className='rounded-0 fixed-top' now={progress} />

                    <SurveyHeader images={[headerImage]} />

                    {surveyNavigation && <DropdownNav
                        sections={surveyNavigation}
                        currentPage={''}
                        buttonClick={menuNavigate}
                    />}

                    <SectionProgress progress={sectionProgress} />

                    <SectionHeader
                        title={SurveyStrings.SECTION_SUBMIT_TITLE}
                        links={false}
                    />

                    <SurveyFooter
                        isSubmit={true}
                        preventForward={canSubmit}
                        btn_forward={() => submitSurvey()}
                        btn_back={() => navigate(`${AppRoute.SURVEY}/${lastSection}` || `${AppRoute.SURVEY}/File`)}
                    />

                </div>

        }
    </>
}

export default SectionSubmit