import { Form, InputGroup, Alert } from 'react-bootstrap';

//MODELS
import { MatrixConfiguration, MatrixColumnConfig, Row, MatrixCallbackValues, CellTypes} from '../../../models/Question-Configuration';
import { MatrixAnswers } from '../../../models/Answers-Configuration';


//UTILS ASSETS AND CONSTATS
import { CurrencyFormat, CurrencyDeformat } from '../../../utils/matrixutils';
import '../../../assets/custom-styles/custom-styles.css';

type MatrixContainerProps = {
    readonly?: boolean,
    config: MatrixConfiguration,
    be_answer: MatrixAnswers,
    preventNavigation?: boolean
    onUserAnswer: (config: MatrixConfiguration, value: any, matrix: MatrixCallbackValues) => void,
}
const MatrixContainer = (props: MatrixContainerProps) => {

    /*
        * This is the Matrix Question component, it only renders an input Matrix,
        * All the automatic operations such as arithmetic operations, dynamic cell
        * disabling, value transfers between different questions etc are performed inside the 
        * container after the component sends user input back through the onUserAnswer callback
        * 
        * The Matrix flow goes as follow:
        * User input -> onUserAnswer -> (sectionParser) matrixStoreAnswers -> (sectionParser) matrixTriggerAction
        * (sectionParser) matrixStoreAnswers
        * Functional operations are performed in the matrixTriggerAction function which then calls matrixStoreAnswers
        * All the operations, including special functions such as outerEnabler and setQuestionError are invoked after
        * updating the answers with the latest values and are always performed in the order in which they are specified:
        * if a cell triggers multiple operations they are iterated and performed in order and the next one is performed
        * only when the current one's result has been set in the answers object
    */
    const { config, be_answer, readonly, preventNavigation, onUserAnswer } = props;


    /*
        * Each cell in the config object stores cell format info, said format can either be currency or
        * a percentage value. Currency gets formatted with divider dots and only accepts 0 and positive numbers
        * meanwhile percentage only accepts 0-100 values.
        * The inputs themselves are actually string inputs that do not accept inputs other than 0-9 digits,
        * this allows the currency inputs to be formatted by this method as '1.000.000' instead of '1000000' 
    */
    const formatValue = (format: string, value: any, type: string) => {

        const val = parseInt(value);
        if (isNaN(val)) return ' '
        else {
            if (type === CellTypes.READONLY) return value
            else {
                if (format === 'currency') {

                    return val
                } else {
                    if (val <= 100) {

                        const returnVal = val.toString();
                        if (returnVal[0] === '0') {
                            if (returnVal.length > 1) return returnVal.slice(1)
                            else return '0';
                        }
                        else return returnVal;
                    } else return ' ';
                }
            }
        }


    };

    /*
        * Matches a cell config to its answer config in order to retrieve cell type
        * This allows the sectionparses to switch specific cells between enabled and disabled state    
    */
    const cellToAnswerCell = (colId: string, cellId: string) => {
        const col = be_answer.columns.find(entry => entry.column_id === colId);
        const cell = col?.column.find(entry => entry.cell_id === cellId);
        return cell?.type;
    }

    return <>

        <div className='matrix__outer'>
            <div className='matrix__inner'>
                <div className='container p-3'>
                    <div className='row'>
                        <div className='col-2 matrix__cells matrix__labels me-3' />

                        <div className='col'>

                            {config.columns.length === 8 && config.id.includes('section_b') &&

                                <div className='row mb-3'>
                                    <div className='col py-3 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#F0F6FB' }}>
                                        {config.banner.banner_one}
                                    </div>
                                    <div className='col py-3 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#D0E2F5' }}>
                                        {config.banner.banner_two}
                                    </div>
                                </div>

                            }

                            {config.columns.length === 4 && config.id.includes('section_b') &&
                                <div className='row mb-3'>
                                    <div className='col-1' />
                                    <div className='col py-3 d-flex justify-content-center align-items-center' style={{ backgroundColor: '#D0E2F5' }}>
                                        {config.banner.banner_one}
                                    </div>
                                </div>
                            }

                        </div>


                    </div>



                    <div className='row'>

                        <div className='col matrix__cells matrix__labels me-3' />

                        {config.columns.map(col => (

                            <div key={`${col.column_id}-label-div-key`} className='col matrix__cells text-align-center'>

                                {col.label}

                            </div>

                        ))}

                    </div>

                    {config.rows.map((row: Row, rowIndex: number) =>

                        <div key={`${row.label}row--label-div-key`} className='row my-5 align-items-center justify-content-center matrixLine'>

                            <div className='col matrix__cells matrix__labels me-3' data-toogle={row.tooltip ? 'tooltip' : ''} title={row.tooltip ? `${row.tooltip}` : ''}>
                                {row.perc ?
                                    <em>
                                        <small>
                                            {row.label}
                                        </small>
                                    </em> :
                                    row.label
                                }
                            </div>

                            {config.columns.map((columnObject: MatrixColumnConfig, columnIndex: number) => {

                                const cell = columnObject.column[rowIndex];
                                return (
                                    <div
                                        key={`${cell.cell_id}-matrix-cell-key`}
                                        className='col matrix__cells'
                                    >


                                        {
                                            cell.noRender ? <span></span> :
                                                cell.format === 'percentage' &&
                                                <InputGroup>
                                                    <Form.Control
                                                        className={cell.labelCell || cell.type === CellTypes.READONLY ? 'p-2 col matrix__labelCell' : (columnObject.prefilled ? 'p-2 col matrix__prefilled' : 'p-2 col')}
                                                        name={cell.cell_id}
                                                        type='string'
                                                        size='sm'
                                                        disabled={
                                                            cell.dynamicType 
                                                            ? 
                                                                cellToAnswerCell(columnObject.column_id, cell.cell_id) === CellTypes.READONLY || cellToAnswerCell(columnObject.column_id, cell.cell_id) === CellTypes.SLAVE 
                                                            :  
                                                                cell.type === CellTypes.READONLY || cell.type === CellTypes.SLAVE || readonly || columnObject.prefilled
                                                        }
                                                        value={cell.negative ? be_answer.columns[columnIndex].column[rowIndex].value : CurrencyFormat(be_answer.columns[columnIndex].column[rowIndex].value)}
                                                        onChange={(e: any) => {
                                                            onUserAnswer(
                                                                config, 
                                                                formatValue(cell.format, CurrencyDeformat(e.target.value), cell.type), 
                                                                { colId: columnObject.column_id, cellId: cell.cell_id})
                                                        }}
                                                        min={cell.negative ? undefined : 0}
                                                        onWheel={(e: any) => e.target.blur()}
                                                    />
                                                    <InputGroup.Text
                                                        className={cell.labelCell ? 'p-2 col matrix__labelCell' : (columnObject.prefilled ? 'matrix__percLabel matrix__prefilled' : 'matrix__percLabel')}

                                                    >
                                                        %
                                                    </InputGroup.Text>
                                                </InputGroup>
                                        }
                                        {
                                            cell.noRender ? <span></span> :
                                                cell.format === 'currency' &&
                                                <Form.Control
                                                    className={
                                                        cell.dynamicType 
                                                        ? 
                                                            cellToAnswerCell(columnObject.column_id, cell.cell_id) === CellTypes.SLAVE ? 'p-2 col matrix__prefilled' : 'p-2 col'
                                                        :
                                                            (columnObject.prefilled || cell.type === (CellTypes.SLAVE)) || cell.type === CellTypes.READONLY ? 'p-2 col matrix__prefilled matrix__One' : 'p-2 col matrix__Two'
                                                    }
                                                    name={cell.cell_id}
                                                    type='string'
                                                    size='sm'
                                                    disabled={
                                                        cell.dynamicType 
                                                        ? 
                                                            cellToAnswerCell(columnObject.column_id, cell.cell_id) === CellTypes.READONLY || cellToAnswerCell(columnObject.column_id, cell.cell_id) === CellTypes.SLAVE 
                                                        :  
                                                            cell.type === CellTypes.READONLY || cell.type === CellTypes.SLAVE || readonly || columnObject.prefilled
                                                    }
                                                    value={    
                                                        cell.negative ? be_answer.columns[columnIndex].column[rowIndex].value : CurrencyFormat(be_answer.columns[columnIndex].column[rowIndex].value)}
                                                    onChange={(e: any) => {
                                                        onUserAnswer( 
                                                            config, 
                                                            formatValue(cell.format, CurrencyDeformat(e.target.value), cell.type), 
                                                            { colId: columnObject.column_id, cellId: cell.cell_id}
                                                        )
                                                    }}
                                                    min={cell.negative ? undefined : 0}
                                                    onWheel={(e: any) => e.target.blur()}
                                                />

                                        }
                                    
                                    </div>)
                            })}

                        </div>
                    )}





                </div>
            </div>
            {be_answer.questionError?.error && preventNavigation &&
                <Alert className='mx-5' variant='danger' onClose={() => console.log('closed')}>{be_answer.questionError.errorMessage}</Alert>
            }
        </div>
    </>
}
export default MatrixContainer