import React, { useEffect, useState } from "react";
//Libraries
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import classNames from "classnames";
//Components
import SurveyErrorToast from "../../crossapp-utils/toast-error/survey-toast-error";
import { Spinner } from "react-bootstrap";
//Models
import QuestionConfiguration from '../../../models/Question-Configuration';
//Services
import UploadService from '../../../services/Upload-Service';
//Constants
import SurveyStrings  from '../../../utils/constants/Strings'
import { BASE_URL } from "../../../utils/constants/environment";

type FileUploaderProps = {
    config: QuestionConfiguration
    isReadOnly?: boolean,
    be_answer: { [key: string]: string },
    onUserAnswer: (config: QuestionConfiguration, value: any) => void
}

const FileUploader = (props: FileUploaderProps) => {
    
    const { config, be_answer, onUserAnswer, isReadOnly = false } = props
    
    //Local var used to display file info
    const [answer, setAnswer] = useState<{ [key: string]: string }>(be_answer);
    const [changed, setChanged] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    //Dropzone props && functions
    const { getRootProps, getInputProps
    } = useDropzone({
        accept: 'application/pdf',
        disabled: isReadOnly,
        multiple: false,
        onDrop: onDrop
    });

    function onDrop(acceptedFiles: File[]) {
        
        if (acceptedFiles[0]) {
            setUploading(true);
            UploadService.uploadFile(acceptedFiles[0])
                .then(res => {
                    setChanged(true);
                    setAnswer({
                        [acceptedFiles[0].name]: BASE_URL +  res.payload
                    });
                })
                .catch(err => {
                    toast.error(<SurveyErrorToast link={true} first_message={SurveyStrings.UPLOAD_ERROR} second_message={SurveyStrings.SAVE_ERROR_LINE_TWO} />);
                })
                .finally(() => setUploading(false))
        };
    };

    //Callback for data saving
    useEffect(() => {
        if(changed) onUserAnswer(config, answer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answer, changed]);

    return <>
        {uploading && 
            <>
                <h2 className="text-primary">Caricamento file in corso</h2>
                <Spinner variant='primary' animation='border'></Spinner>
            </>
        }
        {!uploading && 
        
        <section className='container mt-1 mb-5'>

            <div {...getRootProps({ className: classNames('dropzone', { 'disabled': isReadOnly }) })}>

                <input {...getInputProps()} />
                <p className='m-0'> Trascina un file o fai clic qui per caricarlo </p>

            </div>
            

            {answer && answer!== null && Object.keys(answer).length > 0 &&

                <div className='container mt-5 dropzone'>

                    <div className="row">

                        <div className="col-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                            </svg>
                        </div>

                        <div className="col-9 col-md-6">
                            {Object.keys(answer).map(key => <p key={`${key}-paragraph`}>{key}</p>)}
                        </div>

                        <div className="col-12 col-md-3">
                            <Button
                                className='btn btn-primary'
                                onClick={() => {
                                    setChanged(true);
                                    setAnswer({});
                                }}
                                >
                                Cancella
                            </Button>
                        </div>

                    </div>

                </div>

            }
        </section>
        }
       
    </>

}

export default FileUploader