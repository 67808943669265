//COMPONENTS
import SurveyHeader from "../header/header";
import { Card } from "react-bootstrap";

//UTILS
import SurveyStrings from "../../../utils/constants/Strings";
import { headerImage } from "../../../assets/images";
import '../../../assets/custom-styles/custom-styles.css';

const IeWarning = () => {

    return <>
        <div className='d-flex flex-column justify-content-center'>

            <SurveyHeader images={[headerImage]} />

            <div className='d-flex justify-content-center w-100 my-5'>

                <Card className='d-flex width_80 cardShadow'>

                    <Card.Header className='customHeaders p-4'>

                        <h4>{SurveyStrings.APP_HEADER}</h4>

                    </Card.Header>

                </Card>
            </div>

        </div>

    </>
}

export default IeWarning;