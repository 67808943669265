//LIBRARIES
import { Button } from 'react-bootstrap';
import XLSX from 'xlsx-js-style';

//MODELS
import SectionConfiguration, { SectionNavigation } from '../../../models/Section-Configuration';
import { MatrixAnswers, QuestionAnswer } from '../../../models/Answers-Configuration';
import QuestionConfiguration, { ConfigTypes, MatrixConfiguration } from '../../../models/Question-Configuration';

//UTILS ASSETS COMPONENTS
import { Selectors, cellstyle, titlestyle, labelstyle, defaultstyle, CellStyleTypes, sortFunction } from './utils';
import QuestionTypes from '../../../utils/constants/Question-Types';
import SurveyStrings from '../../../utils/constants/Strings';
import '../../../assets/custom-styles/custom-styles.css';
import { toast } from 'react-toastify';
import SurveyErrorToast from '../../crossapp-utils/toast-error/survey-toast-error';


type SectionDownloaderProps = {
    config: SectionConfiguration[],
    answers: (MatrixAnswers | QuestionAnswer)[],
    dropdown: SectionNavigation[],
    recap?: boolean
};

const SectionDownloader = (props: SectionDownloaderProps) => {
    const { config, answers, dropdown, recap } = props;

    //parses a section config and returns twin arrays of arrays to parse into xlsx sheet
    function parser(input: SectionConfiguration): { workingAOA: string[][], workingStyleAOA: any[][] } {

        let workingAOA: string[][] = [];
        let workingStyleAOA: any[][] = [];
        input.questions.forEach((question, index) => {
            if (question.config_type === ConfigTypes.MATRIX) {
                const parsingOutput: string[][] = [];
                const parsingStyle: any[] = [];

                const current = { ...question } as MatrixConfiguration;
                const currentAnswer = answers.find(entry => "matrix_id" in entry && entry.matrix_id === question.id) as MatrixAnswers;

                if (currentAnswer && currentAnswer.show) {

                    //MATRIX TITLE
                    parsingOutput.push([current.question]);
                    parsingStyle.push([CellStyleTypes.TITLE_BOX])

                    //MATRIX COLUMNS
                    const columnLabels: string[] = [''];
                    const columnLabelsStyle: any[] = [''];
                    current.columns.forEach((column, columnIndex) => {
                        if(current.id === 'a01') {
                            if(columnIndex !== 0){
                                columnLabels.push(column.label);
                                columnLabelsStyle.push(CellStyleTypes.COLUMN_LABEL);
                            }
                        } else if (current.id.includes('section_b') || current.id.includes('section_bs') || current.id === 'cc01c' || current.id === 'cc01d' || current.id === 'cc_01_f' || current.id === 'cd_12') {
                            if(current.columns.length === 8) {
                                if(columnIndex >3){
                                columnLabels.push(column.label);
                                columnLabelsStyle.push(CellStyleTypes.COLUMN_LABEL);
                                }
                            } else {
                                columnLabels.push(column.label);
                                columnLabelsStyle.push(CellStyleTypes.COLUMN_LABEL);
                            }
                        } else {
                            columnLabels.push(column.label);
                            columnLabelsStyle.push(CellStyleTypes.COLUMN_LABEL);
                        }
                    });
                    parsingOutput.push(columnLabels);
                    parsingStyle.push(columnLabelsStyle);

                    //MATRIX ROWS
                    current.rows.forEach((row, rowIndex) => {

                        const newIndex = parsingOutput.length;

                        parsingOutput[newIndex] = [row.label];
                        parsingStyle[newIndex] = [CellStyleTypes.LABEL_CELL]

                        currentAnswer.columns.forEach((col, colIndex) => {

                            if(current.id === 'a01'){
                                if(colIndex !== 0) {
                                    parsingOutput[newIndex].push(col.column[rowIndex].value !== 'Infinity' ? col.column[rowIndex].value : '  ');
                                    parsingStyle[newIndex].push(CellStyleTypes.MATRIX_CELL);
                                }

                            }else if(current.id.includes('section_b') || current.id.includes('section_bs') || current.id === 'cc01c' || current.id === 'cc01d' || current.id === 'cc_01_f' || current.id === 'cd_12'){
                                if(current.columns.length === 8) {
                                    if(colIndex > 3) {
                                        parsingOutput[newIndex].push(col.column[rowIndex].value !== 'Infinity' ? col.column[rowIndex].value : '  ');
                                        parsingStyle[newIndex].push(CellStyleTypes.MATRIX_CELL);
                                    }
                                } else {
                                    parsingOutput[newIndex].push(col.column[rowIndex].value !== 'Infinity' ? col.column[rowIndex].value : '  ');
                                    parsingStyle[newIndex].push(CellStyleTypes.MATRIX_CELL);
                                }
                            } else {
                                parsingOutput[newIndex].push(col.column[rowIndex].value !== 'Infinity' ? col.column[rowIndex].value : '  ');
                                parsingStyle[newIndex].push(CellStyleTypes.MATRIX_CELL);
                            }
                        });

                    });
                    parsingOutput.push(['  ']);
                    parsingStyle.push(['  ']);

                    workingAOA = workingAOA.concat(parsingOutput);
                    workingStyleAOA = workingStyleAOA.concat(parsingStyle);
                }

            } else {

                const parsingOutput: string[][] = [];
                const parsingStyle: any[] = []

                const current = { ...question } as QuestionConfiguration;
                const currentAnswer = answers.find(entry => entry.id === question.id) as QuestionAnswer;
                if (currentAnswer && currentAnswer.show) {

                    parsingOutput.push([current.question]);
                    parsingStyle.push([CellStyleTypes.TITLE_BOX])

                    switch (current.type) {

                        case (QuestionTypes.FILE): {
                            if (!currentAnswer.value) currentAnswer.value = {};
                            if (Object.keys(currentAnswer.value).length > 0) {

                                Object.keys(currentAnswer.value).forEach(key => {
                                    parsingOutput.push([SurveyStrings.UPLOADED_FILE, key]);
                                    parsingStyle.push([CellStyleTypes.LABEL_CELL, CellStyleTypes.ANSWER_CELL]);
                                })
                            } else {
                                parsingOutput.push([SurveyStrings.UPLOADED_NO_FILE]);
                                parsingStyle.push([CellStyleTypes.ANSWER_CELL])
                            }
                            break
                        }

                        case (QuestionTypes.GROUP_DATE): {
                            const first = Array.isArray(currentAnswer.value) && currentAnswer.value[0] !== undefined;
                            const second = Array.isArray(currentAnswer.value) && currentAnswer.value[1] !== undefined;
                            const start = first ? new Date(currentAnswer.value[0]).toLocaleDateString() : ' ';
                            const end = second ? new Date(currentAnswer.value[1]).toLocaleDateString() : ' ';

                            parsingOutput.push([SurveyStrings.START_DATE, start]);
                            parsingStyle.push([CellStyleTypes.LABEL_CELL, CellStyleTypes.ANSWER_CELL]);
                            parsingOutput.push([SurveyStrings.END_DATE, end]);
                            parsingStyle.push([CellStyleTypes.LABEL_CELL, CellStyleTypes.ANSWER_CELL]);
                            break

                        }
                        case (QuestionTypes.S_CHECKBOX): {
                            if (!currentAnswer.value) currentAnswer.value = {};
                            current.lines?.forEach(line => {
                                const temp = [line];
                                const styleTemp = [CellStyleTypes.LABEL_CELL];

                                Object.keys(currentAnswer.value).forEach(key => {
                                    if (currentAnswer.value[key].includes(line)) {
                                        temp.push(key);
                                        styleTemp.push(CellStyleTypes.ANSWER_CELL)
                                    } else {
                                        temp.push('  ');
                                        styleTemp.push(CellStyleTypes.ANSWER_CELL)
                                    }
                                })
                                parsingOutput.push(temp);
                                parsingStyle.push(styleTemp);
                            });

                            break
                        }
                        case (QuestionTypes.RADIO): {

                            if (!currentAnswer.value) currentAnswer.value = {};
                            current.lines?.forEach((line) => {
                                if (currentAnswer.value && currentAnswer.value[line]) {
                                    const temp = currentAnswer.value[line];

                                    const valueToPush = (temp && temp[0]) ? temp[0] : '  '
                                    parsingOutput.push(
                                        [line, valueToPush]
                                    );
                                    parsingStyle.push(
                                        [CellStyleTypes.LABEL_CELL, CellStyleTypes.ANSWER_CELL]
                                    )
                                }
                            });
                            break
                        }
                        case (QuestionTypes.RADIO_PERCENTAGE): {
                            if (currentAnswer.value && Object.keys(currentAnswer.value).length > 0) {
                                const pushval = Object.keys(currentAnswer.value)[0];

                                parsingOutput.push([pushval, currentAnswer.value[pushval]]);
                                parsingStyle.push(
                                    [CellStyleTypes.LABEL_CELL, CellStyleTypes.ANSWER_CELL]
                                );

                            } else {
                                parsingOutput.push([' ', ' ']);
                                parsingStyle.push(
                                    [CellStyleTypes.LABEL_CELL, CellStyleTypes.ANSWER_CELL]
                                );
                            }
                            break
                        }
                        case (QuestionTypes.DOUBLE_RADIO): {
                            
                            parsingOutput.push(['', current.radio_columns!].flat());
                            parsingStyle.push([CellStyleTypes.COLUMN_LABEL, CellStyleTypes.COLUMN_LABEL, CellStyleTypes.COLUMN_LABEL])
                            
                            current.lines?.forEach(line => {
                                const temp = [line];
                                const tempStyle = [CellStyleTypes.LABEL_CELL]
                                current.radio_columns?.forEach(col => {
                                    if(currentAnswer.value[col] && currentAnswer.value[col][line]) temp.push(currentAnswer.value[col][line]);
                                    else temp.push(' ');
                                    tempStyle.push(CellStyleTypes.ANSWER_CELL);
                                })
                                parsingOutput.push(temp);
                                parsingStyle.push(tempStyle);
                            })                            
                            break

                        }
                        case (QuestionTypes.SINGLE_DATE): {
                            parsingOutput.push([currentAnswer.value.slice(-4)]);
                            parsingStyle.push([CellStyleTypes.ANSWER_CELL])
                            break
                        }
                        case (QuestionTypes.NUMBER): {
                            current.lines?.forEach((line, lineIndex) => {
                                parsingOutput.push([line, currentAnswer.value[lineIndex]]);
                                parsingStyle.push([CellStyleTypes.LABEL_CELL, CellStyleTypes.ANSWER_CELL])

                            });
                            break
                        }

                        case (QuestionTypes.PROJECT):
                        case (QuestionTypes.SINGLE_OPTION):
                        case (QuestionTypes.PERCENTAGE):
                        case (QuestionTypes.FREE_TEXT): {
                            if (Array.isArray(currentAnswer.value)) {
                                currentAnswer.value.forEach(val => {
                                    parsingOutput.push([val]);
                                    parsingStyle.push([CellStyleTypes.ANSWER_CELL]);
                                })
                            } else {
                                parsingOutput.push([currentAnswer.value.toString()]);
                                parsingStyle.push([CellStyleTypes.ANSWER_CELL]);
                            }

                            break
                        }
                        case (QuestionTypes.GROUP_OPTION): {
                            if (Array.isArray(currentAnswer.value)) {
                                parsingOutput.push([currentAnswer.value.toString()]);
                                parsingStyle.push([CellStyleTypes.ANSWER_CELL]);
                            } else {
                                parsingOutput.push([currentAnswer.value.toString()]);
                                parsingStyle.push([CellStyleTypes.ANSWER_CELL]);
                            }
                            break
                        }
                        default: break
                    };
                    parsingOutput.push([' '], [' ']);
                    parsingStyle.push(['  '], [' ']);

                };

                workingAOA = workingAOA.concat(parsingOutput);
                workingStyleAOA = workingStyleAOA.concat(parsingStyle);
            };
        });
        return {
            workingAOA: workingAOA,
            workingStyleAOA: workingStyleAOA
        }
    };

    //whole document columns and rows sizing
    function bookSizing(dataOutput: XLSX.WorkBook): XLSX.WorkBook {
        Object.keys(dataOutput[Selectors.SHEETS]).forEach(sheet => {
            const smcolinfo = {
                wch: 15
            };
            const colinfo = {
                wch: 30
            };
            const rowinfo = {
                hpx: 50
            };
            const rowsLength: number = Object.keys(dataOutput[Selectors.SHEETS][sheet]).length;
            const output = [];
            for (let i = 0; i < rowsLength; i++) output.push(rowinfo);
            dataOutput[Selectors.SHEETS][sheet][Selectors.COLS] = [colinfo, smcolinfo, smcolinfo, smcolinfo, smcolinfo, smcolinfo, smcolinfo, smcolinfo, smcolinfo]
            dataOutput[Selectors.SHEETS][sheet][Selectors.ROWS] = output;

        });
        return dataOutput
    };

    //parses whole config
    async function wholeSheetGenerator() {
        const dataOutput = XLSX.utils.book_new();
        const styleOutput = XLSX.utils.book_new();
        

        //section parsing
        config.forEach(entry => {
            if(dropdown.find(nav => nav.name === entry.section.id)) {

            
                const sheetTitle = dropdown.find(nav => nav.name === entry.section.id)?.sheet;
                const parsingOutput = parser(entry);
                XLSX.utils.book_append_sheet(dataOutput, XLSX.utils.aoa_to_sheet(parsingOutput.workingAOA), sheetTitle);
                XLSX.utils.book_append_sheet(styleOutput, XLSX.utils.aoa_to_sheet(parsingOutput.workingStyleAOA), sheetTitle);

                //subsection parsing
                if (entry.sub_sections && entry.sub_sections.length > 0) {
                    entry.sub_sections.forEach((subsection, index) => {
                        const subsectionTitle = dropdown.find(nav => nav.name === entry.section.id)?.sheet + ' - Progetto ' + (index + 1).toString();
                        const subsectionParsingOutput = parser(subsection);
                        XLSX.utils.book_append_sheet(dataOutput, XLSX.utils.aoa_to_sheet(subsectionParsingOutput.workingAOA), subsectionTitle);
                        XLSX.utils.book_append_sheet(styleOutput, XLSX.utils.aoa_to_sheet(subsectionParsingOutput.workingStyleAOA), subsectionTitle);
                    })
                }
            }
        });

        //style excel file comparing data output and style only output
        Object.keys(dataOutput[Selectors.SHEETS]).forEach(sheet => {

            const index = Object.keys(dataOutput[Selectors.SHEETS][sheet]).sort(sortFunction);
            if (!index.includes(Selectors.MERGES)) index.unshift(Selectors.MERGES);
            if (!index.includes(Selectors.REF)) index.unshift(Selectors.REF);

            index.forEach((ind, indIndex) => {

                if (ind !== Selectors.MERGES && ind !== Selectors.REF
                    && styleOutput[Selectors.SHEETS][sheet][ind]
                    && styleOutput[Selectors.SHEETS][sheet][ind].v) {

                    switch (styleOutput[Selectors.SHEETS][sheet][ind].v) {
                        case CellStyleTypes.TITLE_BOX: {

                            const merge = {
                                s: { c: 0, r: indIndex - 2 },
                                e: { c: 12, r: indIndex - 2 }
                            };

                            const workingsheet = dataOutput[Selectors.SHEETS][sheet];
                            if (workingsheet[Selectors.MERGES]) workingsheet[Selectors.MERGES]?.push(merge);
                            else workingsheet[Selectors.MERGES] = [merge];

                            return dataOutput[Selectors.SHEETS][sheet][ind][Selectors.STYLE] = { ...titlestyle }
                        }
                        case CellStyleTypes.COLUMN_LABEL:

                            return dataOutput[Selectors.SHEETS][sheet][ind][Selectors.STYLE] = { ...labelstyle };

                        case CellStyleTypes.LABEL_CELL:
                            return dataOutput[Selectors.SHEETS][sheet][ind][Selectors.STYLE] = { ...cellstyle };

                        case CellStyleTypes.ANSWER_CELL: {
                            const merge = {
                                s: { c: 0, r: indIndex - 2 },
                                e: { c: 2, r: indIndex - 1 }
                            };
                            const workingsheet = dataOutput[Selectors.SHEETS][sheet];
                            if (workingsheet[Selectors.MERGES]) workingsheet[Selectors.MERGES]?.push(merge);
                            else workingsheet[Selectors.MERGES] = [merge];

                            return dataOutput[Selectors.SHEETS][sheet][ind][Selectors.STYLE] = { ...defaultstyle }
                        }
                        default:
                            return dataOutput[Selectors.SHEETS][sheet][ind][Selectors.STYLE] = { ...defaultstyle };

                    }

                }
            })

        })

        const resizedBook = bookSizing(dataOutput);
        XLSX.writeFile(resizedBook, 'survey.xlsx');
    };

    const clickHandler = () => {
        wholeSheetGenerator()
            .then(res => {
                toast.success(SurveyStrings.DOWNLOAD_WIN, { autoClose: 3000 });
            })
            .catch(err => {
                console.log('Download error : ', err);
                toast.error(
                    <SurveyErrorToast
                        link={true}
                        first_message={SurveyStrings.DOWNLOAD_FAIL}
                        second_message={SurveyStrings.DOWNLOAD_FAIL_TWO}
                    />
                )
            })
    }


    return <>

        <Button
            key='section-downloader-button'
            className={recap ? 'my-2': 'navButton mt-3 mt-md-5 mb-3'}
            onClick={clickHandler}
        >
            {SurveyStrings.EXPORT_DATA}
        </Button>

    </>
}

export default SectionDownloader;