import User from '../models/User';
import { EndPoints } from '../utils/constants/EndPoints';
import { BASE_URL } from '../utils/constants/environment';

type ResponseUser = {
    message: string,
    payload: {
        user: User
    },
    status: number
}

class UserService {

    async getOneUser(id: string): Promise<ResponseUser> {
       
        const user : RequestInit = {
            method: 'GET',
            headers: {
                'content-type': 'Application/json',
                //'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
            },
            
        };
        
        console.log(`${BASE_URL}${EndPoints.USER}/${id}`);
        console.log(`${BASE_URL}`);
        console.log(`${EndPoints.USER}`);
        console.log(`${id}`);
        return await fetch(`${BASE_URL}${EndPoints.USER}/${id}`, user)
            .then(res => res.json()) 
    }

    
}

export default new UserService();
