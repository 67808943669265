import { Alert, Button, Col, Row } from 'react-bootstrap';

//MODELS
import { QuestionAnswer, MatrixAnswers } from '../../../models/Answers-Configuration';
import SectionConfiguration from '../../../models/Section-Configuration';
import { SectionNavigation } from '../../../models/Section-Configuration';

//CONSTANTS && UTILS
import Navigation from '../../../utils/constants/Navigation';
import '../../../assets/custom-styles/custom-styles.css';
import SurveyStrings from '../../../utils/constants/Strings';

//COMPONENTS
import SectionDownloader from '../../section-components/section-downloader/section-downloader';
import { useParams } from 'react-router';

type SurveyFooterProps = {
    config?: SectionConfiguration[],
    answers?: (MatrixAnswers | QuestionAnswer)[],
    dropdown?: SectionNavigation[],
    preventNavigation?: boolean;
    isSubmit?: boolean;
    preventForward?: boolean;
    currentSection?: SectionConfiguration;
    btn_forward?: (e: any) => void;
    btn_back?: (e: any) => void;
    pdf_download?: (e: any) => void;
};

const SurveyFooter = (props: SurveyFooterProps) => {

    const params = useParams();

    const { isSubmit = false, preventForward, preventNavigation, config, answers, dropdown, currentSection, btn_back, btn_forward } = props;

    const onPreventForward = () => {
        if (preventForward === undefined) {
            return preventNavigation
        } else if (!preventForward) {
            return !preventForward
        }
    }

    const onPreventBack = () => {
        if (dropdown && params.sectionId === dropdown[0].id) {
            return !preventNavigation
        } else if (preventForward === undefined) {
            return preventNavigation
        } else if (!preventForward) {
            return preventForward
        }
    }

    const changeButtonForward = () => {
        if (isSubmit) {
            return SurveyStrings.SEND_DATA
        } else if (currentSection?.sub_sections) {
            return SurveyStrings.SECTION_FORWARD
        } else return SurveyStrings.FORWARD
    }

    const handleAlert = () => {
        if (isSubmit && !preventForward) {
            return <Row className='justify-content-center'>
                <Col sm='4'>
                    <Alert variant='danger'>{SurveyStrings.ERROR_SUBMIT}</Alert>
                </Col>
            </Row>
        } else if (preventNavigation) {
            return <Row className='justify-content-center'>
                <Col sm='4'>
                    <Alert variant='danger'>{SurveyStrings.ERROR_FOOTER}</Alert>
                </Col>
            </Row>
        }
    }

    return <>

        <div>
            {handleAlert()}

            <Row className='d-flex justify-content-between mx-5 pb-5'>
                <Col sm='4'>
                    {btn_back && dropdown &&
                        <Button className='navButton' onClick={(e: any) => btn_back(Navigation.BACK)}
                            disabled={onPreventBack()}>
                            {currentSection?.sub_sections ? SurveyStrings.SECTION_BACK : SurveyStrings.BACK}
                        </Button>
                    }
                </Col>

                <Col sm='4'>
                    {config && answers && dropdown &&
                        <>
                            <Col xs='12'>

                                <SectionDownloader config={config} answers={answers} dropdown={dropdown} />

                            </Col>
                        </>
                    }
                </Col>

                <Col sm='4'>
                    {btn_forward &&
                        <Button
                            className='navButton'
                            onClick={(e: any) => btn_forward(Navigation.FORWARD)}
                            disabled={onPreventForward()}>
                            {changeButtonForward()}
                        </Button>
                    }
                </Col>
            </Row>
        </div>

    </>
}

export default SurveyFooter;