import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { Form, InputGroup } from "react-bootstrap"
import QuestionConfiguration from "../../../models/Question-Configuration"

type FreeTextProps = {
    config: QuestionConfiguration;
    be_answer: string;
    isReadOnly?: boolean;
    onUserAnswer: (config: QuestionConfiguration, value: any) => void;
}


const FreeText = (props: FreeTextProps) => {
    const { config, be_answer, isReadOnly, onUserAnswer } = props;

    const [answer, setAnswer] = useState(be_answer ?? '');

    const isMobile = useMediaQuery({query: '(max-width: 800px'});

    const handleChange = (e: any) => {

        const val = e.target.value
        onUserAnswer(config, val);
        setAnswer(val);
    };

    return <>
        <InputGroup>
            <Form.Control
                type='text'
                as="textarea"
                rows={isMobile ? 4 * Math.ceil(answer.length / 100) :  Math.ceil(answer.length / 100)}
                value={answer}
                onChange={handleChange}
                maxLength={1000}
                disabled={isReadOnly}
            />
        </InputGroup>
    </>
}

export default FreeText