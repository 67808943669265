import '../../../assets/custom-styles/custom-styles.css';
import AppRoute from '../../../utils/constants/AppRoute';
import { SURVEYS_URL } from '../../../utils/constants/environment';
import { EndPoints } from '../../../utils/constants/EndPoints';
import SurveyStrings from '../../../utils/constants/Strings';
import { Link } from 'react-router-dom';
import SessionService from '../../../services/Session-Service';
import SessionStorage from '../../../utils/constants/SessionStorage';
import User from '../../../models/User';
import TemplateService from '../../../services/Template-Service';

type SectionHeaderProps = {
    title: string;
    description?: string;
    links?: boolean;
}

const SectionHeader = (props: SectionHeaderProps) => {

    const { title, description, links } = props;

    const downloadTemplate = async () => {
        const user: User = JSON.parse(SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_OBJ) as string)
        const base64File: string = (await TemplateService.getOneTemplate(user.role)).payload.template.encoded_xls
        const blob = await (await fetch(base64File)).blob()
        const url = window.URL.createObjectURL(
            new Blob([blob])
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
            'download',
            'template.xlsx'
        )
        document.body.appendChild(link)
        link.click()
        link.parentNode?.removeChild(link)
    }

    return <>

        <div className='d-flex justify-content-center w-100 my-5'>

            <div className='customHeaders m-2 width_80 p-3'>

                <h3 className='my-3'>{title}</h3>



                {description && <h5 className='my-3' dangerouslySetInnerHTML={{ __html: description }}></h5>}
                {links &&
                    <>
                        <p
                            onClick={downloadTemplate}
                        >
                            {SurveyStrings.HOMEBOX_TEMPLATE_LINK}
                        </p>    
                        <p>
                            <a
                                rel='noreferrer'
                                href={AppRoute.FAQ}
                            >
                                {SurveyStrings.HOMEBOX_FAQ_LINK}
                            </a>
                        </p>
                        <p>
                            <a
                                target='_blank'
                                rel='noreferrer'
                                href={`${SURVEYS_URL}${EndPoints.WALKTHROUGH}`}
                            >
                                {SurveyStrings.HOMEBOX_WALKTHROUGH_LINK}
                            </a>
                        </p>
                        <p>
                            <Link
                                target='_blank'
                                rel='noreferrer'
                                to={`${AppRoute.SURVEY}${AppRoute.SUPPORT}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID) ?? '_'}/${SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID) ?? '_'}?type=ticket`}
                            >
                                {SurveyStrings.SUPPORT}
                            </Link>
                        </p>
                    </>
                }


            </div>

        </div>
    </>
}

export default SectionHeader;