import { MatrixAnswers, QuestionAnswer } from "../models/Answers-Configuration";
import ProgressModel from "../models/Progress";
import SectionConfiguration from "../models/Section-Configuration";
import { SectionProgressModel } from "../models/Section-Progress";
import { EndPoints } from "../utils/constants/EndPoints";

import { BASE_URL } from "../utils/constants/environment";
import SessionStorage from "../utils/constants/SessionStorage";

import SessionService from "./Session-Service";

type ResponseAnswers = {
    message: string,
    payload: {
        answers: (MatrixAnswers | QuestionAnswer) [],
        closed?: boolean
        progress: ProgressModel,
        config?: SectionConfiguration[]
        surveyId: string,
        userId: string
    },
    status: number
}

type ResponseCheck = {
    message: string,
    payload: {
        canSubmit: boolean,
        percentage: number
        sectionProgresses: SectionProgressModel[]
    },
    status:string
}

type ResponseProgress = {
    message: string,
    payload: { progress: ProgressModel },
    status: string
}

export type UpdateResponse = {
    message: string,
    payload: {
        answers : (MatrixAnswers | QuestionAnswer) [],
        progress: ProgressModel,
        config: {
            sections: SectionConfiguration[]
        }
    },
    status: number
}
class AnswersService {

    async getAnswers(): Promise<ResponseAnswers> {

        const params = {
            userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID),
            surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID)
        };

        const requestAnswers: RequestInit = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        
        return await fetch(`${BASE_URL}${EndPoints.ANSWERS}/${params.userId}/${params.surveyId}`, requestAnswers)
            .then(res => res.json())
        
    };

    async updateAnswers(answers: MatrixAnswers | QuestionAnswer []): Promise <UpdateResponse>{
        const body = {
            userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID),
            surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID),
            answers: answers
        };
         const options : RequestInit = {
             method: 'PUT',
             headers: {
                 'Content-type': 'Application/json',
                 //'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}`
             },
             body: JSON.stringify(body)
         }
         return await fetch(`${BASE_URL}${EndPoints.ANSWERS}/${EndPoints.SAVE}`, options)
            .then(res => res.json())
            
    }

    async submitSurvey() {
        const params = {
            userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID),
            surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID)
        };

        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-type': 'Application/json',
                //'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}` 
            }
        };

        return await fetch(`${BASE_URL}${EndPoints.SURVEY}/${EndPoints.CLOSE}/${params.userId}/${params.surveyId}`, options)
            .then(res => res.json())
    }

    async finalCheck(): Promise<ResponseCheck> {
        const params = {
            userId: SessionService.getItem(SessionStorage.SESSION_STORAGE_USER_ID),
            surveyId: SessionService.getItem(SessionStorage.SESSION_STORAGE_SURVEY_ID)
        };

        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-type': 'Application/json',
                //'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}` 
            }
        };

        return await fetch(`${BASE_URL}${EndPoints.PROGRESS}/${EndPoints.FINAL_CHECK}/${params.userId}/${params.surveyId}`, options)
            .then(res => res.json())
    }

    async getProgress(userId: string, surveyId: string): Promise<ResponseProgress> {
       
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-type': 'Application/json',
                //'Authorization': `Bearer ${SessionService.getItem(SESSION_STORAGE_JWT_TOKEN)}` 
            }
        };

        return await fetch(`${BASE_URL}${EndPoints.PROGRESS}/${userId}/${surveyId}`, options)
            .then(res => res.json())
    }
}

export default new AnswersService()