enum AppRoute {
    ADMIN= '/admin',
    USERTABLE= '/userstables',
    USERLIST= '/userlists',
    USER='/user',
    FAQ = '/faq',
    LOGIN = '/',
    SURVEY = '/survey',
    SUPPORT = '/support',
    SUBMIT = '/submit',
    RECAP = '/recap',
    LINK = '/:uniqueLink',
    LINK_ERROR = '/link-error'
}

export default AppRoute