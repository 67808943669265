import { Card } from 'react-bootstrap';
import { Faq } from '../../../models/Survey-Faq-Configuration';
import '../../../assets/custom-styles/custom-styles.css';

interface FaqCardProps {
    faq: Faq
}

const FaqCard = (props: FaqCardProps) => {
    
    const { faq } = props;
    
    return <>
       
        <Card className='p-4 my-4 border-0'>
            <h4 className='width_80 my-2 text-primary'>
                {faq.title}
            </h4>

            {
                faq.description && 
                faq.description.map((line: string, index: number) => 
                    
                    <p 
                        key={`${index}-faq-desc-key`}
                        className='faq__text' 
                        dangerouslySetInnerHTML={{ __html: line}} 
                    />
                )
            }

            {
                faq.bulletList && 
                
                <ul className='faq__text'>
                    {faq.bulletList.map((bullet: string, index: number) => 
                        <li 
                            key={`${index}-bullet-li`} 
                            dangerouslySetInnerHTML={{ __html: bullet}} 
                        />
                    )}
                </ul>
                
            }

            {
                faq.bottomText &&
                <p className='faq__text' dangerouslySetInnerHTML={{ __html: faq.bottomText}} />
                    
                
            }
        </Card>

    </>
}

export default FaqCard;